import { observer } from "mobx-react-lite";
import { Input } from "@nextui-org/react";
import formStore from "../../stores/FormStore";

const PersonalityForm = observer(() => {
  return (
    <div className="flex flex-col gap-4 p-4">
      <Input
        label="Personality Prompt"
        placeholder="Enter your personality prompt"
        value={formStore.personalityPrompt}
        onChange={(e) => formStore.setPersonalityPrompt(e.target.value)}
      />
    </div>
  );
});

export default PersonalityForm;
