import React from "react";

const Doom: React.FC = () => {
  return (
    <iframe
      src="https://www.gameslol.net/data/dosbox/index.php?url=doom.zip"
      title="Doom Game"
      className="w-full h-full border-none min-h-[500px]"
      sandbox="allow-scripts allow-same-origin allow-popups"
      allow="camera; microphone; fullscreen"
    ></iframe>
  );
};

export default Doom;
