import { makeAutoObservable, runInAction } from "mobx";
import i18n from "../i18n";
import { User as Auth0User } from "@auth0/auth0-react";
import axios from "axios";
import { DEFAULT_BACKGROUND } from "data/background";
import appStore from "./AppStore";
import { jwtDecode } from "jwt-decode";
import { notificationManager } from "components/UI/notificationManager";

interface CustomUser extends Auth0User {
  [key: string]: any;
}

export interface VPNStatusType {
  key: string;
  src?: string;
  label: string;
  translateKey: string;
}

export const VPNStatuses: VPNStatusType[] = [
  {
    key: "none",
    label: "No VPN",
    translateKey: "no_vpn",
    src: "/images/logo.svg",
  },
  {
    key: "shadownode",
    src: "/images/shadownode_logo.png",
    label: "Shadow Node",
    translateKey: "shadownode",
  },
  {
    key: "nordvpn",
    src: "/images/nordvpn.png",
    label: "NordVPN",
    translateKey: "nordvpn",
  },
  {
    key: "mullvad",
    src: "/images/mullvad.png",
    label: "Mullvad",
    translateKey: "mullvad",
  },
  {
    key: "surfshark",
    src: "/images/surfshark.svg",
    label: "Surfshark",
    translateKey: "surfshark",
  },
];

class UserStore {
  currentLanguage: string = i18n.language;
  user: CustomUser | null = null;
  email: string | null = null;
  name: string | null = null;
  picture: string | null = null;
  isAuthenticated: boolean = false;
  isLoading: boolean = false;
  accessToken: string | null = null;
  debugMode: boolean = process.env.REACT_APP_DEV_MODE === "true";
  ipAddress: string | null = null;
  backgroundUrl: string = DEFAULT_BACKGROUND;
  synkTokens: number = 0;
  tokenExpiration: number | null = null;
  tokenIssuedAt: number | null = null;
  expirationTimerId: NodeJS.Timeout | null = null;
  currentTime: number = Date.now();
  synkBalance: number = 1; // static value
  currentTimeTimerId: NodeJS.Timeout | null = null;
  address: string | null = null;
  showDate: boolean = true;
  showPreboot = false;
  vpnStatus: string = localStorage.getItem("vpnStatus") || "no_vpn";
  isVPNChanging: boolean = false;
  showAlertModal: boolean = false;
  alertMinutesLeft: number = 0;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });

    this.loadPreferences();
    this.fetchIpAddress();
    this.startCurrentTimeTimer();
  }

  startCurrentTimeTimer() {
    this.currentTimeTimerId = setInterval(() => {
      runInAction(() => {
        this.currentTime = Date.now();
      });
    }, 1000);
  }

  stopCurrentTimeTimer() {
    if (this.currentTimeTimerId) {
      clearInterval(this.currentTimeTimerId);
      this.currentTimeTimerId = null;
    }
  }

  toggleShowDate() {
    this.showDate = !this.showDate;
  }

  async initializeAuth(auth0: any) {
    try {
      if (auth0.isLoading) {
        runInAction(() => {
          this.setIsLoading(true);
        });
        return;
      }

      if (auth0.isAuthenticated && auth0.user) {
        this.setUser(auth0.user);
        const token = await auth0.getAccessTokenSilently();
        this.setAccessToken(token);

        const decodedToken: any = jwtDecode(token);
        const expirationTime = decodedToken.exp * 1000;
        const issuedAt = decodedToken.iat ? decodedToken.iat * 1000 : null;
        this.setTokenExpiration(expirationTime, issuedAt);

        if (expirationTime && this.tokenIssuedAt) {
          this.startTokenExpirationTimer(expirationTime - this.currentTime);
        }

        await this.fetchIpAddress();
        await appStore.fetchApps();

        this.setIsAuthenticated(true);
        this.setShowPreboot(true);
        this.setIsLoading(false);
      } else {
        this.clearSession();
        runInAction(() => {
          this.setIsLoading(false);
        });
      }
    } catch (error) {
      this.handleError(error, "error_initializing_authentication");

      this.clearSession();
      runInAction(() => {
        this.setIsLoading(false);
      });
    }
  }

  async setVPNStatus(statusKey: string) {
    if (this.isVPNChanging) {
      return;
    }

    const statusObj = VPNStatuses.find((status) => status.key === statusKey);
    if (statusObj) {
      this.isVPNChanging = true;

      const translatedLabel = i18n.t(statusObj.translateKey);

      notificationManager.notify({
        message: i18n.t("patience"),
        description: `${translatedLabel} ${i18n.t("being_activated")}`,
        duration: 2.5,
      });

      this.vpnStatus = statusKey;
      localStorage.setItem("vpnStatus", statusKey);
      this.logAction("setVPNStatus", { status: statusKey });

      await appStore.handleVPNChange(statusKey);

      this.isVPNChanging = false;
    } else {
      console.warn(`Unknown VPN Status: ${statusKey}`);
    }
  }

  get availableVPNStatuses(): VPNStatusType[] {
    return VPNStatuses;
  }

  toggleVPN() {
    const currentIndex = this.availableVPNStatuses.findIndex(
      (status) => status.key === this.vpnStatus
    );
    const nextIndex = (currentIndex + 1) % this.availableVPNStatuses.length;
    this.setVPNStatus(this.availableVPNStatuses[nextIndex].key);
  }

  setShowPreboot(value: boolean) {
    this.showPreboot = value;
  }

  loadPreferences() {
    const isDevMode = process.env.REACT_APP_DEV_MODE === "true";

    const storedLanguage = localStorage.getItem("currentLanguage");
    if (storedLanguage) {
      this.currentLanguage = storedLanguage;
      i18n.changeLanguage(storedLanguage);
    }

    if (isDevMode) {
      const storedDebugMode = localStorage.getItem("debugMode");
      if (storedDebugMode !== null) {
        this.debugMode = storedDebugMode === "true";
      }
    } else {
      this.debugMode = false;
      localStorage.setItem("debugMode", "false");
    }

    const storedBackgroundUrl = localStorage.getItem("backgroundUrl");
    if (storedBackgroundUrl) {
      this.backgroundUrl = storedBackgroundUrl;
    }

    const storedIpAddress = localStorage.getItem("ipAddress");
    if (storedIpAddress) {
      this.ipAddress = storedIpAddress;
    }

    const storedAddress = localStorage.getItem("address");
    if (storedAddress) {
      this.address = storedAddress;
    }
  }

  setTokenExpiration(expirationTime: number | null, issuedAt: number | null) {
    this.tokenExpiration = expirationTime;
    this.tokenIssuedAt = issuedAt;
    this.logAction("setTokenExpiration", {
      tokenExpiration: this.tokenExpiration,
      tokenIssuedAt: this.tokenIssuedAt,
    });
  }

  startTokenExpirationTimer(expiresIn: number) {
    if (this.expirationTimerId) {
      clearTimeout(this.expirationTimerId);
    }

    const checkIntervals = [15, 10, 5]; // minutes
    const now = Date.now();
    const expirationTime = now + expiresIn;

    checkIntervals.forEach((minutes) => {
      const timeLeft = expirationTime - now - minutes * 60 * 1000;
      if (timeLeft > 0) {
        setTimeout(() => {
          runInAction(() => {
            this.showAlertModal = true;
          });
        }, timeLeft);
      }
    });

    this.expirationTimerId = setTimeout(() => {
      this.handleTokenExpiration();
    }, expiresIn);
  }
  closeAlertModal() {
    this.showAlertModal = false;
  }

  handleTokenExpiration() {
    this.clearSession();
    notificationManager.notify({
      message: i18n.t("session_expired"),
      description: i18n.t("session_expired"),
      duration: 30,
    });
  }

  clearSession() {
    this.setIsAuthenticated(false);
    this.setUser(null);
    this.setAccessToken(null);
    this.ipAddress = null;
    this.address = null;
    this.tokenExpiration = null;
    this.clearTokenExpirationTimer();
    this.setIsLoading(false);
  }

  clearTokenExpirationTimer() {
    if (this.expirationTimerId) {
      clearTimeout(this.expirationTimerId);
      this.expirationTimerId = null;
    }
  }

  setAccessToken(accessToken: string | null) {
    this.accessToken = accessToken;
    this.logAction("setAccessToken", { accessToken });

    const cookieDomain = process.env.REACT_APP_COOKIE_DOMAIN || ".synk.ws";
    const isSecure = window.location.protocol === "https:";

    if (accessToken) {
      document.cookie = `WorkspaceCookie=${accessToken}; path=/; domain=${cookieDomain}; ${
        isSecure ? "Secure;" : ""
      } SameSite=None`;
    } else {
      document.cookie = `WorkspaceCookie=; path=/; domain=${cookieDomain}; ${
        isSecure ? "Secure;" : ""
      } SameSite=None; expires=Thu, 01 Jan 1970 00:00:00 GMT`;
    }
  }

  get tokenRemainingTime() {
    if (this.tokenExpiration) {
      return this.tokenExpiration - this.currentTime;
    }
    return 0;
  }

  get formattedTokenRemainingTime() {
    const remainingTime = this.tokenExpiration
      ? this.tokenExpiration - this.currentTime
      : 0;
    if (remainingTime > 0) {
      const totalSeconds = Math.floor(remainingTime / 1000);
      const hours = String(Math.floor(totalSeconds / 3600)).padStart(2, "0");
      const minutes = String(Math.floor((totalSeconds % 3600) / 60)).padStart(
        2,
        "0"
      );
      const seconds = String(totalSeconds % 60).padStart(2, "0");
      return `${hours}:${minutes}:${seconds}`;
    }
    return "00:00:00";
  }

  get tokenProgress() {
    if (this.tokenExpiration && this.tokenIssuedAt) {
      const totalDuration = this.tokenExpiration - this.tokenIssuedAt;
      const remainingTime = this.tokenExpiration - this.currentTime;
      const progress = (remainingTime / totalDuration) * 100;
      return Math.max(Math.min(progress, 100), 0);
    }
    return 0;
  }

  get tokenColor() {
    if (this.tokenProgress > 60) {
      return "success";
    } else if (this.tokenProgress > 30) {
      return "warning";
    } else {
      return "danger";
    }
  }

  setLanguage(lng: string) {
    i18n.changeLanguage(lng);
    this.currentLanguage = lng;
    localStorage.setItem("currentLanguage", lng);
    this.logAction("setLanguage", { lng });
  }

  setDebugMode(debugMode: boolean) {
    if (process.env.REACT_APP_DEV_MODE === "true") {
      this.debugMode = debugMode;
      localStorage.setItem("debugMode", String(debugMode));
      this.logAction("setDebugMode", { debugMode });
    }
  }

  setBackground(url: string) {
    this.backgroundUrl = url;
    localStorage.setItem("backgroundUrl", url);
    this.logAction("setBackground", { backgroundUrl: this.backgroundUrl });
  }

  setIpAddress(ip: string) {
    this.ipAddress = ip;
    localStorage.setItem("ipAddress", ip);
    this.logAction("setIpAddress", { ipAddress: this.ipAddress });
  }

  setUser(user: CustomUser | null) {
    this.user = user;
    this.email = user?.email || null;
    this.name = user?.name || user?.nickname || user?.sub || null;
    this.picture = user?.picture || null;

    const address = user?.nickname || null;
    this.setAddress(address);

    this.logAction("setUser", {
      user,
      email: this.email,
      name: this.name,
      picture: this.picture,
      address: this.address,
    });
  }

  setAddress(address: string | null) {
    this.address = address;
    if (address) {
      localStorage.setItem("address", address);
    } else {
      localStorage.removeItem("address");
    }
    this.logAction("setAddress", { address });
  }

  setIsAuthenticated(isAuthenticated: boolean) {
    this.isAuthenticated = isAuthenticated;
    this.logAction("setIsAuthenticated", { isAuthenticated });
  }

  setIsLoading(isLoading: boolean) {
    this.isLoading = isLoading;
    this.logAction("setIsLoading", { isLoading });
  }

  async refreshAccessToken(auth0: any) {
    try {
      const token = await auth0.getAccessTokenSilently({
        ignoreCache: true,
      });
      this.setAccessToken(token);
    } catch (error) {
      this.handleError(error, "error_refreshing_access_token");
    }
  }

  async fetchIpAddress() {
    try {
      const response = await axios.get("https://api.ipify.org?format=json");
      const ip = response.data.ip;

      if (ip && typeof ip === "string") {
        this.ipAddress = ip;
        localStorage.setItem("ipAddress", ip);
        this.logAction("fetchIpAddress", { ipAddress: ip });
      } else {
        throw new Error("Adresse IP invalide reçue de l'API.");
      }
    } catch (error) {
      this.handleError(error, "error_fetching_ip_address");
    }
  }

  handleError(error: any, defaultMessageKey: string, params?: any) {
    const errorMessage =
      this.debugMode && error?.message
        ? error.message
        : i18n.t(defaultMessageKey, params);
    notificationManager.notify({
      message: i18n.t("error"),
      description: errorMessage,
      duration: 30,
    });
  }

  logAction(actionName: string, payload: any) {
    if (this.debugMode) {
      console.log(`Action: ${actionName}`, payload);
    }
  }
}

const userStore = new UserStore();
export default userStore;
