import { useAuth0 } from "@auth0/auth0-react";
import { useTranslation } from "react-i18next";
import { BackgroundBeams } from "components/UI/background-beams";
import { Button } from "components/UI/Button";
import { Card, CardContent } from "components/UI/Card";
import userStore from "stores/UserStore";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { PopupConfigOptions } from "@auth0/auth0-spa-js";
import { useNotification } from "components/UI/NotificationProvider";

export const Login = observer(() => {
  const {
    loginWithPopup,
    loginWithRedirect,
    isAuthenticated,
    isLoading: auth0Loading,
  } = useAuth0();
  const { t } = useTranslation();
  const notify = useNotification();
  const [error, setError] = useState<string | null>(null);

  const handleRedirectLogin = () => {
    loginWithRedirect();
  };

  const btn = (
    <p
      className="text-sm cursor-pointer font-medium text-white opacity-80 font-helveticaneue tracking-tight-4 gradient-stroke-less no-underline hover:underline hover:text-lightColor/90 hover:gradient-stroke"
      onClick={handleRedirectLogin}
    >
      {t("popup_alternative")}
    </p>
  );

  const handleLogin = async () => {
    try {
      userStore.setIsLoading(true);
      notify({
        message: t("popup_opened_title"),
        description: t("popup_opened_description"),
        btn: btn,
        duration: 10,
      });

      let width = 500;
      let height = 600;

      if (window.innerWidth < 768) {
        width = 390;
        height = 600;
      }

      const left = window.screenX + window.outerWidth - width;
      const top = window.screenY + 150;

      const popup = window.open(
        "",
        "auth0:authorize:popup",
        `width=${width},height=${height},left=${left},top=${top},resizable`
      );

      if (!popup) {
        throw new Error("Popup blocked");
      }

      const config: PopupConfigOptions = { popup };
      await loginWithPopup(undefined, config);

      userStore.setShowPreboot(true);
    } catch (error) {
      setError(t("popup_blocked_description"));
      notify({
        message: t("popup_blocked_title"),
        description: t("popup_blocked_description"),
        btn: btn,
        duration: 10,
        placement: "bottomRight",
        showProgress: true,
      });
      console.error("Erreur lors de l'ouverture de la popup de login :", error);
      userStore.setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!auth0Loading && !isAuthenticated) {
      userStore.setIsLoading(false);
    }
  }, [auth0Loading, isAuthenticated]);

  return (
    <div className="relative flex h-screen w-full items-center justify-center overflow-hidden bg-black antialiased">
      <div className="absolute inset-0 bg-black animate-fadeOut z-20 pointer-events-none"></div>

      <div
        className="absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlur opacity-0"
        style={{
          backgroundImage: 'url("/images/login.svg")',
          zIndex: 1,
          width: "100%",
          height: "100%",
        }}
      ></div>

      <div
        className="absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
        style={{
          backgroundImage: 'url("/images/Ellipse1.png")',
          zIndex: 0,
        }}
      ></div>

      <Card className="relative z-10 flex flex-col items-center w-[300px] sm:w-[300px] md:w-[350px] lg:w-[480px] max-w-full p-6 gap-6 animate-slideUp">
        <CardContent className="flex flex-col items-center gap-6 py-12">
          <div className="flex justify-center">
            <img
              src="/images/logo.svg"
              alt="Logo"
              className="w-[80px] h-[80px]"
            />
          </div>

          <div className="text-center">
            <h2 className="text-sm text-white font-monospace tracking-tight-4">
              {t("welcome")}
            </h2>
            <p className="text-sm text-white font-monospace tracking-tight-4 gradient-stroke">
              {t("connect_prompt")}
            </p>
          </div>

          <div className="w-full flex justify-center">
            <Button
              size="default"
              variant="login"
              onClick={handleLogin}
              isLoading={userStore.isLoading}
              disabled={userStore.isLoading}
            >
              {userStore.isLoading ? t("connecting") : t("login")}
            </Button>
          </div>
        </CardContent>
      </Card>

      <BackgroundBeams />
    </div>
  );
});
