import React from "react";
import WindowTopBar from "./WindowTopBar";
import clsx from "clsx";

interface WindowPreviewProps {
  title: string;
  children: React.ReactNode;
  windowSize: { width: number; height: number };
  isLoading?: boolean;
  appLogoUrl?: string;
  component?: React.ComponentType;
}

const WindowPreview: React.FC<WindowPreviewProps> = ({
  title,
  children,
  windowSize,
  isLoading,
  appLogoUrl,
  component,
}) => {
  const windowClasses = clsx(
    "relative flex flex-col box-border",
    "bg-customGray/30 rounded-custom backdrop-blur-dock shadow-custom",
    "cursor-default"
  );

  return (
    <div
      className={windowClasses}
      style={{ width: windowSize.width, height: windowSize.height }}
    >
      <WindowTopBar
        title={title}
        isMaximized={false}
        onClose={() => {}}
        onMinimize={() => {}}
        onMaximize={() => {}}
        isPreview={true}
        appLogoUrl={appLogoUrl}
      />
      <div className="flex-grow overflow-hidden w-full h-full pt-0 px-4 pb-4 box-border">
        {isLoading ? (
          <div className="w-full h-full bg-gray-200" />
        ) : component ? (
          <div className="w-full h-full">{React.createElement(component)}</div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default WindowPreview;
