import React from "react";
import BtnDate from "./components/BtnDate";
import BtnSynkLogo from "./components/BtnSynkLogo";
import BtnUserProfile from "./components/BtnUserProfile";
import { observer } from "mobx-react-lite";
import selectionStore from "stores/SelectionStore";

const TopBar: React.FC = observer(() => {
  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button !== 0) return;

    const interactiveElements = ["BUTTON", "A", "INPUT", "SELECT", "TEXTAREA"];
    if (interactiveElements.includes((e.target as HTMLElement).tagName)) {
      return;
    }

    e.preventDefault();

    selectionStore.startSelection(e.clientX, e.clientY);
  };

  return (
    <div
      className="fixed top-3 mx-auto w-[90%] px-2 py-1 h-12 flex items-center justify-between text-white z-10 animate-slideUp"
      style={{ userSelect: "none" }}
      onMouseDown={handleMouseDown}
    >
      <div className="hidden sm:flex items-center space-x-2">
        <BtnDate />
      </div>

      <div className="hidden sm:flex absolute left-1/2 transform -translate-x-1/2">
        <BtnSynkLogo />
      </div>

      <div className="flex sm:hidden flex-1 justify-start ml-4">
        <BtnSynkLogo />
      </div>

      <div className="flex items-center space-x-2">
        <BtnUserProfile />
      </div>
    </div>
  );
});

export default React.memo(TopBar);
