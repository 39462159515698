import { cn } from "lib/utils";
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";

interface HoverEffectProps {
  items: {
    key: string;
    title: string;
    icon: React.ReactNode;
    onClick: () => void;
    disabled: boolean;
  }[];
  selectedKey: string;
  className?: string;
  isDisabled?: boolean;
}

export const HoverEffect: React.FC<HoverEffectProps> = ({
  items,
  selectedKey,
  className,
  isDisabled = false,
}) => {
  const [hoveredIndex, setHoveredIndex] = useState<number | null>(null);

  return (
    <div
      className={cn(
        "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 py-10",
        className
      )}
    >
      {items.map((item, idx) => (
        <button
          key={item.key}
          className={cn(
            "relative group block p-2 h-full w-full focus:outline-none",
            item.disabled || isDisabled
              ? "cursor-not-allowed opacity-50"
              : "cursor-pointer"
          )}
          onMouseEnter={() =>
            !item.disabled && !isDisabled && setHoveredIndex(idx)
          }
          onMouseLeave={() => setHoveredIndex(null)}
          onClick={() => !item.disabled && !isDisabled && item.onClick()}
          disabled={item.disabled || isDisabled}
        >
          {item.key === selectedKey && (
            <motion.span
              className="absolute inset-0 h-full w-full rounded-3xl bg-synkColor/40"
              layoutId="selectedBackground"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.3 },
              }}
              exit={{
                opacity: 0,
                transition: { duration: 0.3 },
              }}
            />
          )}

          <AnimatePresence>
            {hoveredIndex === idx && !item.disabled && !isDisabled && (
              <motion.span
                className={cn(
                  "absolute inset-0 h-full w-full rounded-3xl",
                  item.key === selectedKey
                    ? "bg-synkColor/20"
                    : "bg-slate-800/[0.8]"
                )}
                layoutId="hoverBackground"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: 1,
                  transition: { duration: 0.15 },
                }}
                exit={{
                  opacity: 0,
                  transition: { duration: 0.15, delay: 0.2 },
                }}
              />
            )}
          </AnimatePresence>

          <Card isSelected={item.key === selectedKey}>
            <div className="flex flex-col items-center gap-4">
              {item.icon}
              <CardTitle>{item.title}</CardTitle>
            </div>
          </Card>
        </button>
      ))}
    </div>
  );
};

export const Card = ({
  className,
  children,
  isSelected,
}: {
  className?: string;
  children: React.ReactNode;
  isSelected?: boolean;
}) => {
  return (
    <div
      className={cn(
        "rounded-2xl h-full w-full p-4 overflow-hidden bg-default-100",
        isSelected ? "border-2 border-synkColor" : "",
        "relative z-20",
        className
      )}
    >
      <div className="relative z-50">
        <div className="p-4">{children}</div>
      </div>
    </div>
  );
};

export const CardTitle = ({
  className,
  children,
}: {
  className?: string;
  children: React.ReactNode;
}) => {
  return (
    <h4 className={cn("text-zinc-100 font-bold tracking-wide mt-4", className)}>
      {children}
    </h4>
  );
};
