import type { ArgsProps } from "antd/es/notification";

class NotificationManager {
  private static instance: NotificationManager;
  private notifyFunction: ((args: ArgsProps) => void) | null = null;

  private constructor() {}

  static getInstance(): NotificationManager {
    if (!NotificationManager.instance) {
      NotificationManager.instance = new NotificationManager();
    }
    return NotificationManager.instance;
  }

  setNotifyFunction(fn: (args: ArgsProps) => void) {
    this.notifyFunction = fn;
  }

  notify(args: ArgsProps) {
    if (this.notifyFunction) {
      this.notifyFunction(args);
    } else {
      console.error("Notification function not set");
    }
  }
}

export const notificationManager = NotificationManager.getInstance();
