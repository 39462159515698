import React from "react";

const MarioKart: React.FC = () => {
  return (
    <div className="relative w-full h-full">
      <iframe
        src="https://www.gameslol.net/data/emul/retro/load.php?sys=snes&r=supermariokart.smc"
        title="Mario Kart"
        className="w-full h-full border-none"
        sandbox="allow-scripts allow-same-origin allow-popups"
        allow="camera; microphone; fullscreen"
      ></iframe>

      <div className="absolute bottom-0 left-0 w-full h-12 bg-[#000]"></div>
    </div>
  );
};

export default MarioKart;
