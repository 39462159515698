import { observer } from "mobx-react-lite";
import MessageGenericPage from "components/MessageGenericPage/MessageGenericPage";

const ExpiredSession: React.FC = observer(() => {
  return (
    <MessageGenericPage
      title={
        <>
          Whoops! <br /> Time's Up.
        </>
      }
      message="It seems your session has timed out or the link you used is taking a
          coffee break. No worries! Click below to get back to Synk and start
          fresh."
      buttonText="Back to Synk"
    />
  );
});

export default ExpiredSession;
