import React from "react";
import { cn } from "lib/utils";

type AsProp<C extends React.ElementType> = {
  as?: C;
};

type PropsToOmit<C extends React.ElementType, P> = keyof (AsProp<C> & P);

type PolymorphicComponentProps<
  C extends React.ElementType,
  Props = {}
> = React.PropsWithChildren<Props & AsProp<C>> &
  Omit<React.ComponentPropsWithoutRef<C>, PropsToOmit<C, Props>>;

type PulsatingButtonProps<C extends React.ElementType> =
  PolymorphicComponentProps<
    C,
    {
      pulseColor?: string;
      duration?: string;
      isLoading?: boolean;
      disabled?: boolean;
      className?: string;
    }
  >;

export function PulsatingButton<C extends React.ElementType = "button">({
  as,
  className,
  children,
  pulseColor = "#0096ff",
  duration = "1.5s",
  isLoading = false,
  disabled,
  ...props
}: PulsatingButtonProps<C>) {
  const Component = as || "button";

  return (
    <Component
      className={cn(
        "relative flex items-center justify-center cursor-pointer bg-gradient-to-b from-lightColor to-lightColor/90 text-neutral-900 focus:ring-2 focus:ring-blue-400 transition gradient-stroke duration-200 hover:opacity-90 px-4 py-2 rounded-xl text-xs font-bold",
        { "cursor-not-allowed": disabled || isLoading },
        { "bg-gradient-to-b from-lightColor/70 to-lightColor/50": isLoading },
        className
      )}
      style={
        {
          "--pulse-color": pulseColor,
          "--duration": duration,
        } as React.CSSProperties
      }
      disabled={disabled || isLoading}
      {...props}
    >
      <div className="relative flex items-center justify-center z-10">
        {isLoading && (
          <svg
            className="animate-spin h-5 w-5 mr-2 text-current"
            fill="none"
            viewBox="0 0 24 24"
            aria-hidden="true"
          >
            {/* Contenu du spinner */}
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        )}
        <span>{children}</span>
      </div>

      {!isLoading && (
        <div className="absolute top-1/2 left-1/2 w-full h-full rounded-lg bg-inherit animate-pulse -translate-x-1/2 -translate-y-1/2" />
      )}
    </Component>
  );
}
