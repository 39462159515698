import React from "react";
import { Image } from "@nextui-org/react";
import { useTranslation } from "react-i18next";
import userStore, { VPNStatusType } from "stores/UserStore";
import { HoverEffect } from "components/UI/card-hover-effect";
import { observer } from "mobx-react-lite";

const VPNTab = observer(() => {
  const { t } = useTranslation();
  const { vpnStatus, availableVPNStatuses, setVPNStatus, isVPNChanging } =
    userStore;

  const handleVPNChange = (key: string) => {
    if (!isVPNChanging) {
      setVPNStatus(key);
    }
  };

  const items = availableVPNStatuses.map((status: VPNStatusType) => ({
    key: status.key,
    title: t(status.translateKey),
    icon: status.src ? (
      <Image
        src={status.src}
        alt={t(status.translateKey)}
        className={
          vpnStatus === status.key
            ? "w-12 h-12"
            : "w-12 h-12 grayscale opacity-50 cursor-not-allowed"
        }
      />
    ) : null,
    onClick: () => handleVPNChange(status.key),
    disabled: isVPNChanging,
  }));

  return (
    <div>
      <HoverEffect
        items={items}
        selectedKey={vpnStatus}
        className="w-full mt-1"
        isDisabled={isVPNChanging}
      />
    </div>
  );
});

export default VPNTab;
