import { observer } from "mobx-react-lite";
import { Input } from "@nextui-org/react";
import formStore from "../../stores/FormStore";

const KnowledgeForm = observer(() => {
  return (
    <div className="flex flex-col gap-4 p-4">
      <Input
        label="Knowledge Link"
        placeholder="Enter knowledge link"
        value={formStore.knowledgeLink}
        onChange={(e) => formStore.setKnowledgeLink(e.target.value)}
      />
    </div>
  );
});

export default KnowledgeForm;
