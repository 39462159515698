import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import selectionStore from "stores/SelectionStore";
import SelectionBox from "./SelectionBox";

const SelectionOverlay: React.FC = observer(() => {
  const handleMouseMove = (e: MouseEvent) => {
    selectionStore.updateSelection(e.clientX, e.clientY);
  };

  const handleMouseUp = () => {
    selectionStore.endSelection();
  };

  useEffect(() => {
    document.addEventListener("mousemove", handleMouseMove);
    document.addEventListener("mouseup", handleMouseUp);
    return () => {
      document.removeEventListener("mousemove", handleMouseMove);
      document.removeEventListener("mouseup", handleMouseUp);
    };
  }, []);

  return (
    <div className="fixed inset-0 z-[8]" style={{ cursor: "default" }}>
      <SelectionBox />
    </div>
  );
});

export default SelectionOverlay;
