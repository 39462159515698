import React, { useState } from "react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
  DropdownSection,
  Switch,
  Avatar,
  AvatarGroup,
  Tabs,
  Tab,
  Input,
  Progress,
  Tooltip,
  Image,
  Card,
  CardBody,
  Snippet,
  Button as NextUIButton,
} from "@nextui-org/react";
import { Button } from "components/UI/Button";
import { observer } from "mobx-react-lite";
import userStore, { VPNStatusType } from "stores/UserStore";
import appStore from "stores/AppStore";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { ExternalLink, SwitchCamera, User } from "lucide-react";
import IpAddressDisplay from "components/IpAddressDisplay/IpAddressDisplay";

const BtnUserProfile: React.FC = observer(() => {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const [isOpen, setIsOpen] = useState(false);

  const {
    ipAddress,
    debugMode,
    setDebugMode,
    currentLanguage,
    setLanguage,
    picture,
    formattedTokenRemainingTime,
    tokenProgress,
    tokenColor,
    synkBalance,
    address,
    setVPNStatus,
    vpnStatus,
    toggleShowDate,
    availableVPNStatuses,
    isVPNChanging,
  } = userStore;

  const handleDebugModeChange = (value: boolean) => {
    setDebugMode(value);
  };

  const handleLanguageChange = (key: React.Key) => {
    setLanguage(key as string);
  };

  const handleLogout = () => {
    userStore.clearSession();
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  const handleChangeDesktop = () => {
    appStore.focusOrLaunchApp("Settings", { initialTab: "appearance" });
    setIsOpen(false);
  };

  const handleOpenVPNSettings = () => {
    appStore.focusOrLaunchApp("Settings", { initialTab: "vpn" });
    setIsOpen(false);
  };

  const handleOpenEtherscan = () => {
    if (address) {
      const etherscanUrl = `https://etherscan.io/address/${address}`;
      window.open(etherscanUrl, "_blank", "noopener,noreferrer");
    }
  };

  const handleCopyAddress = async () => {
    if (address) {
      try {
        await navigator.clipboard.writeText(address);
      } catch (err) {
        console.error("Failed to copy: ", err);
      }
    }
  };

  const profileInfoSection = (
    <DropdownSection
      title={t("profile")}
      showDivider
      key="profile_info_section"
    >
      <DropdownItem
        key="profile_info"
        className="gap-2 text-ellipsis overflow-hidden cursor-default"
        aria-label={t("profile_info")}
      >
        <div className="w-full">
          <div className="font-semibold">{t("signed_in_as")}</div>
          <div className="flex items-center justify-between pr-3">
            {address && (
              <div className="flex">
                <Snippet
                  variant="bordered"
                  hideSymbol={true}
                  onClick={handleCopyAddress}
                  onDoubleClick={handleCopyAddress}
                  tooltipProps={{
                    content: t("copy_address"),
                    disableAnimation: true,
                    placement: "top",
                    offset: 7,
                  }}
                  classNames={{
                    base: ["border-none", "px-0", "py-0"],
                    pre: ["w-[160px]", "truncate"],
                    copyButton: ["data-[hover=true]:bg-lightColor/10"],
                  }}
                >
                  {address}
                </Snippet>

                <Tooltip placement="top" content={t("open_on_etherscan")}>
                  <NextUIButton
                    isIconOnly
                    onClick={handleOpenEtherscan}
                    aria-label={t("open_on_etherscan")}
                    variant="light"
                    className="group inline-flex items-center justify-center box-border appearance-none select-none whitespace-nowrap font-normal subpixel-antialiased overflow-hidden tap-highlight-transparent data-[pressed=true]:scale-[0.97] outline-none data-[focus-visible=true]:z-10 data-[focus-visible=true]:outline-2 data-[focus-visible=true]:outline-focus data-[focus-visible=true]:outline-offset-2 gap-2 rounded-small px-0 !gap-0 transition-transform-colors-opacity motion-reduce:transition-none bg-transparent min-w-8 w-8 h-8 group relative z-10 text-large text-inherit data-[hover=true]:bg-lightColor/10"
                  >
                    <ExternalLink className="w-4 h-4" />
                  </NextUIButton>
                </Tooltip>
              </div>
            )}
          </div>
        </div>
      </DropdownItem>
    </DropdownSection>
  );

  const sessionExpirationSection = (
    <DropdownSection
      title={t("session_expiration")}
      showDivider
      key="session_expiration_section"
    >
      <DropdownItem
        key="session_expiration"
        className="flex justify-center items-center w-full cursor-default"
        aria-label={t("session_expiration")}
      >
        <div className="font-semibold">
          <Input
            label={t("token_expires_in")}
            value={formattedTokenRemainingTime}
            isReadOnly
            disabled
            className="max-w-xs"
            aria-label={t("token_expires_in")}
          />
        </div>
        <div className="font-semibold">
          <Progress
            size="md"
            radius="md"
            value={tokenProgress}
            color={tokenColor}
            showValueLabel={true}
            className="mt-2"
            aria-label={t("token_progress")}
          />
        </div>
      </DropdownItem>
    </DropdownSection>
  );

  const vpnStatusObj = availableVPNStatuses.find(
    (status) => status.key === vpnStatus
  );

  const VPNSection = (
    <DropdownSection title={t("vpn")} showDivider key="vpn_section">
      <DropdownItem
        key="vpn"
        className="flex items-center justify-between cursor-default"
        aria-label={t("vpn")}
      >
        <div className="align-center bg-default-100 my-2 flex shrink-0 items-center justify-center gap-5 self-stretch rounded-large bg-content1 px-3 py-3 shadow-small w-[242px]">
          {vpnStatusObj?.src && (
            <Avatar
              src={vpnStatusObj.src}
              alt={t(vpnStatusObj.translateKey)}
              className="h-7"
              radius="full"
              size="sm"
            />
          )}

          <div className="line-clamp-2 text-left text-tiny font-medium text-default-700">
            {vpnStatusObj ? t(vpnStatusObj.translateKey) : t("unknown_vpn")}{" "}
            {isVPNChanging ? t("being_activated") : t("activated")}
          </div>

          <NextUIButton
            radius="md"
            size="sm"
            color="success"
            variant="shadow"
            onClick={handleOpenVPNSettings}
          >
            {t("switch_vpn")}
          </NextUIButton>
        </div>
      </DropdownItem>
    </DropdownSection>
  );

  const synkBalanceSection = (
    <DropdownSection
      title={t("synk_balance")}
      showDivider
      key="synk_balance_section"
    >
      <DropdownItem
        key="synk_balance"
        className="flex justify-between items-center w-full mt-2 cursor-default"
        aria-label={t("synk_balance")}
      >
        <Input
          label={t("synk_remaining")}
          value={`${synkBalance} SYNK`}
          isReadOnly
          disabled
          className="max-w-xs"
          aria-label={t("synk_remaining")}
        />
      </DropdownItem>
    </DropdownSection>
  );

  const debugModeSection =
    process.env.REACT_APP_DEV_MODE === "true" ? (
      <DropdownSection
        title={t("debug_mode")}
        showDivider
        key="debug_mode_section"
      >
        <DropdownItem
          key="debugmode"
          className="h-14 gap-2"
          aria-label={t("debug_mode")}
        >
          <Switch
            color="success"
            isSelected={debugMode}
            onValueChange={handleDebugModeChange}
            size="sm"
            aria-label={t("toggle_debug_mode")}
          >
            {debugMode ? t("on") : t("off")}
          </Switch>
        </DropdownItem>
      </DropdownSection>
    ) : null;

  const ipAddressSection = (
    <DropdownSection
      title={t("ip_address")}
      showDivider
      key="ip_address_section"
    >
      <DropdownItem
        key="ipaddress"
        className="flex justify-between items-center w-full mt-2 cursor-default"
        aria-label={t("ip_address")}
      >
        <IpAddressDisplay
          ipAddress={ipAddress}
          showRefreshButton={true}
          onRefresh={() => {
            userStore.fetchIpAddress();
          }}
        />
      </DropdownItem>
    </DropdownSection>
  );

  const languageSection = (
    <DropdownSection title={t("language")} showDivider key="language_section">
      <DropdownItem
        key="language"
        className="h-14 gap-2 cursor-default"
        aria-label={t("language_selection")}
      >
        <Tabs
          aria-label={t("language_options")}
          color="success"
          variant="solid"
          selectedKey={currentLanguage}
          onSelectionChange={handleLanguageChange}
          classNames={{
            base: "w-full",
            tabList: "w-full",
          }}
        >
          <Tab key="en" title={t("english")} />
          <Tab key="zh" title={t("chinese")} />
        </Tabs>
      </DropdownItem>
    </DropdownSection>
  );

  const otherItems = [
    <DropdownItem
      key="change_desktop"
      aria-label={t("change_desktop")}
      onPress={handleChangeDesktop}
    >
      {t("change_desktop")}
    </DropdownItem>,
    <DropdownItem
      key="toggle_date"
      aria-label={userStore.showDate ? t("hide_date") : t("show_date")}
      onPress={toggleShowDate}
    >
      {userStore.showDate ? t("hide_date") : t("show_date")}
    </DropdownItem>,
    <DropdownItem
      key="switch_wallet"
      onPress={handleLogout}
      aria-label={t("switch_wallet")}
    >
      {t("switch_wallet")}
    </DropdownItem>,
    <DropdownItem
      key="logout"
      className="text-danger mb-3"
      onPress={handleLogout}
      color="danger"
      aria-label={t("logout")}
    >
      {t("logout")}
    </DropdownItem>,
  ];

  const sections: React.ReactElement[] = [
    profileInfoSection,
    sessionExpirationSection,
    VPNSection,
    synkBalanceSection,
    debugModeSection,
    ipAddressSection,
    languageSection,
    ...otherItems,
  ].filter(
    (section): section is React.ReactElement =>
      section !== null && section !== undefined
  );

  return (
    <Dropdown
      isOpen={isOpen}
      onOpenChange={(open) => setIsOpen(open)}
      closeOnSelect={false}
      classNames={{
        base: "before:shadow-custom",
        content: [
          "overflow-auto max-h-[80vh]",
          "p-0 bg-customGray/60 backdrop-blur-dock border-small border-divider shadow-custom relative",
        ],
      }}
      placement="bottom-end"
    >
      <DropdownTrigger>
        <Button variant="topbar" size="topbar" aria-label={t("open_user_menu")}>
          <Avatar
            isBordered
            color={tokenColor}
            radius="full"
            src={picture || undefined}
            alt={t("user_avatar")}
            showFallback
            fallback={<User className="w-5 h-5" fill="currentColor" />}
          />
        </Button>
      </DropdownTrigger>

      <DropdownMenu
        variant="faded"
        aria-label={t("user_menu")}
        itemClasses={{
          base: [
            "rounded-md",
            "data-[hover=true]:bg-lightColor/10 data-[hover=true]:border-lightColor/30",
          ],
        }}
        className="p-3 max-h-[80vh]"
      >
        {sections}
      </DropdownMenu>
    </Dropdown>
  );
});

export default BtnUserProfile;
