import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import isolatedAppStore from "../store/isolatedAppStore";
import { Button, Tooltip } from "@nextui-org/react";
import {
  Copy,
  Settings,
  Maximize,
  Minimize,
  PanelLeftOpen,
  PanelLeftClose,
  SquarePlus,
} from "lucide-react";
import { useNavigate, useParams } from "react-router-dom";
import publicApiClient from "utils/publicApiClient";
import { notificationManager } from "components/UI/notificationManager";
import { useIsDesktop } from "utils/useIsDesktop";

const Header: React.FC = observer(() => {
  const [isExtending, setIsExtending] = useState(false);
  const navigate = useNavigate();
  const { sessionId } = useParams<{ sessionId: string }>();
  const isDesktop = useIsDesktop();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    notificationManager.notify({
      message: "Link Copied",
      description: "The link has been copied to the clipboard.",
      type: "success",
    });
  };

  const formatTime = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${minutes}:${secs < 10 ? "0" : ""}${secs}`;
  };

  useEffect(() => {
    return () => {
      if (isolatedAppStore.intervalId) {
        clearInterval(isolatedAppStore.intervalId);
        isolatedAppStore.intervalId = null;
      }
    };
  }, []);

  useEffect(() => {
    if (sessionId) {
      const hasExtended = sessionStorage.getItem(`hasExtended_${sessionId}`);
      if (hasExtended === "true") {
        isolatedAppStore.setHasExtended(true);
      }
    }
  }, [sessionId]);

  const handleExtendTime = async () => {
    if (isExtending || isolatedAppStore.hasExtended || !sessionId) return;

    setIsExtending(true);
    try {
      const response = await publicApiClient.post(
        `/v1/demo/${sessionId}/extend`
      );
      if (response.data && response.data.success) {
        const { runningUntil: newRunningUntil } = response.data.details;
        isolatedAppStore.updateRunningUntil(newRunningUntil);
        isolatedAppStore.setHasExtended(true);
        sessionStorage.setItem(`hasExtended_${sessionId}`, "true");
        notificationManager.notify({
          message: "Success",
          description: "Time successfully extended.",
          type: "success",
        });
      } else {
        throw new Error("Cannot extend time.");
      }
    } catch (error: any) {
      notificationManager.notify({
        message: "Error",
        description: "Sorry, but you cannot extend the time.",
        type: "error",
      });
      isolatedAppStore.setHasExtended(true);
      sessionStorage.setItem(`hasExtended_${sessionId}`, "true");
    } finally {
      setIsExtending(false);
    }
  };
  return (
    <header className="h-[50px] w-full px-4 z-10 relative grid items-center">
      {isDesktop ? (
        <div className="grid grid-cols-3 items-center">
          {/* Left Controls */}
          <div className="flex items-center space-x-2">
            <Tooltip
              content={
                isolatedAppStore.isFullscreen ? "Exit Fullscreen" : "Fullscreen"
              }
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                onClick={() => isolatedAppStore.toggleFullscreen()}
                size="sm"
                isIconOnly
                variant="light"
                className="w-6 h-7"
              >
                {isolatedAppStore.isFullscreen ? (
                  <Minimize width={15} height={15} />
                ) : (
                  <Maximize width={15} height={15} />
                )}
              </Button>
            </Tooltip>

            <Tooltip
              content={
                isolatedAppStore.isSidebarOpen
                  ? "Close Sidebar"
                  : "Open Sidebar"
              }
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                onClick={() => isolatedAppStore.toggleSidebar()}
                size="sm"
                variant="light"
                isIconOnly
                className="w-6 h-7"
              >
                {isolatedAppStore.isSidebarOpen ? (
                  <PanelLeftClose size={15} />
                ) : (
                  <PanelLeftOpen size={15} />
                )}
              </Button>
            </Tooltip>
          </div>

          {/* Center Controls */}
          <div className="flex items-center justify-center gap-2">
            <Tooltip
              content="Copy Link"
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                className="w-6 h-7"
                variant="light"
                onClick={handleCopyLink}
                size="sm"
                isIconOnly
              >
                <Copy size={14} />
              </Button>
            </Tooltip>

            <div className="text-white font-logo text-[1.4rem] leading-[100%] tracking-[-0.08em] gradient-stroke">
              Synk
            </div>

            <Tooltip
              content="Settings"
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                className="w-6 h-7"
                variant="light"
                size="sm"
                isIconOnly
                onClick={() => {
                  isolatedAppStore.toggleDrawer();
                }}
              >
                <Settings size={14} />
              </Button>
            </Tooltip>
          </div>

          {/* Right Controls */}
          <div className="flex items-center justify-end space-x-2">
            <Tooltip
              content={
                isolatedAppStore.hasExtended
                  ? "Cannot extend time again"
                  : "Extend Time"
              }
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                variant="flat"
                size="sm"
                radius="full"
                className="shadow-custom bg-customGray/50 backdrop-blur-custom"
                startContent={<SquarePlus size={14} />}
                onClick={handleExtendTime}
                isDisabled={isolatedAppStore.hasExtended || isExtending}
                isLoading={isExtending}
              >
                More time
              </Button>
            </Tooltip>
            <div className="font-mono">
              {isolatedAppStore.remainingTime !== null
                ? formatTime(isolatedAppStore.remainingTime)
                : "--:--"}
            </div>
          </div>
        </div>
      ) : (
        <div className="flex items-center justify-between">
          {/* Left Section */}
          <div className="flex items-center">
            {/* Synk Logo */}
            <div className="text-white font-logo text-[1.4rem] leading-[100%] tracking-[-0.08em] gradient-stroke">
              Synk
            </div>

            {/* Copy Link Button */}
            <Tooltip
              content="Copy Link"
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                className="w-6 h-7 ml-2"
                variant="light"
                onClick={handleCopyLink}
                size="sm"
                isIconOnly
              >
                <Copy size={14} />
              </Button>
            </Tooltip>

            {/* Settings Button */}
            <Tooltip
              content="Settings"
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                className="w-6 h-7 ml-2"
                variant="light"
                size="sm"
                isIconOnly
                onClick={() => {
                  isolatedAppStore.toggleDrawer();
                }}
              >
                <Settings size={14} />
              </Button>
            </Tooltip>
          </div>

          {/* Right Section */}
          <div className="flex items-center">
            {/* Extend Time Button */}
            <Tooltip
              content={
                isolatedAppStore.hasExtended
                  ? "Cannot extend time again"
                  : "Extend Time"
              }
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                variant="flat"
                size="sm"
                radius="full"
                className="shadow-custom bg-customGray/50 backdrop-blur-custom"
                startContent={<SquarePlus size={14} />}
                onClick={handleExtendTime}
                isDisabled={isolatedAppStore.hasExtended || isExtending}
                isLoading={isExtending}
              >
                More time
              </Button>
            </Tooltip>
            {/* Remaining Time */}
            <div className="font-mono ml-2">
              {isolatedAppStore.remainingTime !== null
                ? formatTime(isolatedAppStore.remainingTime)
                : "--:--"}
            </div>
          </div>
        </div>
      )}
    </header>
  );
});

export default Header;
