import { observer } from "mobx-react-lite";
import MessageGenericPage from "components/MessageGenericPage/MessageGenericPage";

const NotFoundPage: React.FC = observer(() => {
  return (
    <MessageGenericPage
      title={
        <>
          Oops! <br /> Page Not Found.
        </>
      }
      message="Looks like you've ventured too far into the internet! This page is
          either taking a break or never existed... No worries! Click below to
          find your way back to Synk."
      buttonText="Back to Synk"
    />
  );
});

export default NotFoundPage;
