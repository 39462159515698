import { observer } from "mobx-react-lite";
import MessageGenericPage from "components/MessageGenericPage/MessageGenericPage";

const RateLimit: React.FC = observer(() => {
  return (
    <MessageGenericPage
      title={
        <>
          Waoooh! <br /> You've Hit the Limit.
        </>
      }
      message="Looks like you're loving Synk a bit too much! You've reached your
          session limit, but no worries—take a break, and things will be fresh
          again in an hour. Good things come to those who wait!"
      buttonText="Back to Synk"
    />
  );
});

export default RateLimit;
