import React from "react";
import WindowEditButtons from "./WindowEditButtons";
import userStore from "stores/UserStore";
import { useTranslation } from "react-i18next";

import { Chip, Avatar } from "@nextui-org/react";

interface WindowTopBarProps {
  title: string;
  onClose: () => void;
  onMinimize?: () => void;
  onMaximize: () => void;
  isMaximized: boolean;
  id?: string;
  isPreview?: boolean;
  isMinimizable?: boolean;
  onRestoreSize?: () => void;
  appLogoUrl?: string;
  vpnStatus?: string;
}

const WindowTopBar: React.FC<WindowTopBarProps> = ({
  title,
  onMinimize,
  onMaximize,
  onClose,
  isMaximized,
  id,
  isPreview,
  isMinimizable,
  onRestoreSize,
  appLogoUrl,
  vpnStatus,
}) => {
  const handleDoubleClick = () => {
    onMaximize();
  };

  const { t } = useTranslation();

  const getVPNLogoUrl = (vpnKey: string): string | undefined => {
    const status = userStore.availableVPNStatuses.find(
      (status) => status.key === vpnKey
    );
    return status?.src;
  };

  const vpnLogoUrl =
    vpnStatus && vpnStatus !== "none" ? getVPNLogoUrl(vpnStatus) : undefined;

  return (
    <div
      className="relative py-1.5 px-3 text-white w-full select-none rounded-t-lg"
      onDoubleClick={handleDoubleClick}
    >
      <div className="flex p-2 items-center justify-between">
        <div className="flex items-center space-x-2">
          {appLogoUrl && (
            <img src={appLogoUrl} alt={`${title} logo`} className="h-4 w-4" />
          )}
          <span>{title.charAt(0).toUpperCase() + title.slice(1)}</span>
        </div>

        <div className="w-[125px]"></div>
      </div>

      {vpnLogoUrl && (
        <div className="absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2 mt-1">
          <Chip
            variant="flat"
            avatar={<Avatar name="VPN" src={vpnLogoUrl} alt="VPN logo" />}
          >
            VPN {t("activated")}
          </Chip>
        </div>
      )}

      {!isPreview && (
        <WindowEditButtons
          onMinimize={onMinimize}
          onMaximize={onMaximize}
          onClose={onClose}
          isMaximized={isMaximized}
          id={id}
          isMinimizable={isMinimizable}
          onRestoreSize={onRestoreSize}
        />
      )}
    </div>
  );
};

export default WindowTopBar;
