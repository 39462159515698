import React from "react";
import { observer } from "mobx-react-lite";
import { TextGenerateEffect } from "components/UI/text-generate-effect";
import { Button } from "components/UI/Button";
import userStore from "stores/UserStore";
import { useTranslation } from "react-i18next";

const message = [
  'This system is provided "as is" without any warranties or guarantees. Use it at your own risk. This is a beta version, and as such, it may contain functional bugs or issues. The purpose of this phase is to conduct tests on our backend and other components of the platform. We encourage beta testers to report any bugs or feedback to help improve the system. For more information, please refer to the ',
  <a
    key="tos-link"
    href="https://docs.synk.ws"
    className="underline"
    target="_blank"
    rel="noopener noreferrer"
  >
    Terms of Service
  </a>,
  ". Synk team.",
];

const Preboot: React.FC = observer(() => {
  const { t } = useTranslation();

  const handleContinue = () => {
    userStore.setShowPreboot(false);
  };

  return (
    <div className="relative flex flex-col items-center justify-center min-h-screen bg-black text-white px-4 overflow-hidden">
      <div className="absolute inset-0 bg-black animate-fadeOut z-0 pointer-events-none"></div>

      <div
        className="absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
        style={{
          backgroundImage: 'url("/images/Ellipse1.png")',
          zIndex: 0,
        }}
      ></div>

      <div className="relative z-10 max-w-xl sm:max-w-[850px] text-center p-4 flex flex-col gap-5 mt-20">
        <TextGenerateEffect words={message} />

        <div className="w-full flex justify-center">
          <Button
            size="default"
            variant="login"
            onClick={handleContinue}
            className="mt-4"
          >
            Continue to App
          </Button>
        </div>
      </div>
    </div>
  );
});

export default Preboot;
