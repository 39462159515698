import { observer } from "mobx-react-lite";
import { Input } from "@nextui-org/react";
import formStore from "../../stores/FormStore";

const DeployForm = observer(() => {
  return (
    <div className="flex flex-col gap-4 p-4">
      <Input
        label="Deploy URL"
        placeholder="Enter deploy URL"
        value={formStore.deployURL}
        onChange={(e) => formStore.setDeployURL(e.target.value)}
      />
    </div>
  );
});

export default DeployForm;
