import { observer } from "mobx-react-lite";
import { ShootingStars } from "components/UI/shooting-stars";
import { Spotlight } from "components/UI/Spotlight";
import { useNavigate } from "react-router-dom";
import { PlaceholdersAndVanishInput } from "components/UI/placeholders-and-vanish-input";
import { Particles } from "components/UI/Particles";

const SynkSearchEngine: React.FC = observer(() => {
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    console.log(e.target.value);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    console.log("submitted");
  };

  const handleAnimationComplete = (query: string) => {
    window.location.href = `https://duckduckgo.com/?q=${encodeURIComponent(
      query
    )}`;
  };

  const placeholders = [
    "What is the current value of Ether?",
    "How does blockchain ensure data security?",
    "What is the current value of Synk?",
    "How to stake cryptocurrencies?",
    "What are the benefits of decentralization?",
  ];

  return (
    <div className="h-screen w-full rounded-md flex flex-col items-center justify-center bg-black/[0.96] antialiased bg-grid-white/[0.02] relative overflow-hidden">
      <div className="absolute inset-0 bg-customGray animate-fadeOut z-0 pointer-events-none"></div>
      <div
        className="absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
        style={{
          backgroundImage: 'url("/images/Ellipse1.png")',
          zIndex: 0,
        }}
      ></div>

      <Particles
        className="absolute inset-0"
        quantity={100}
        ease={80}
        color={"#ffffff"}
        refresh
      />

      <Spotlight
        className="-top-40 left-0 md:left-60 md:-top-20"
        fill="#22dcd1"
      />

      <div className="z-50 flex flex-col justify-center items-center px-4">
        <p className="text-center uppercase text-5xl md:text-8xl lg:text-[6rem] font-bold bg-clip-text text-transparent bg-gradient-to-b from-synkColor/20 to-synkColor/60 inset-x-0">
          Synk search
        </p>
        <div className="mt-[-10px] md:mt-[-22px] lg:mt-[-22px] w-[280px] md:w-full">
          <PlaceholdersAndVanishInput
            placeholders={placeholders}
            onChange={handleChange}
            onSubmit={handleSubmit}
            onAnimationComplete={handleAnimationComplete}
          />
        </div>
      </div>

      <ShootingStars />
    </div>
  );
});

export default SynkSearchEngine;
