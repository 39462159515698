import React from "react";
import { observer } from "mobx-react-lite";
import { useAuth0 } from "@auth0/auth0-react";
import userStore from "stores/UserStore";
import appStore from "stores/AppStore";
import selectionStore from "stores/SelectionStore";
import { Login } from "components/Login/Login";
import Preboot from "components/Preboot/Preboot";
import { useTranslation } from "react-i18next";
import Window from "components/Window/Window";
import Dock from "./components/Dock/Dock";
import TopBar from "./components/TopBar/TopBar";
import { isImage, isVideo } from "data/background";
import DebugDisclaimer from "./components/DebugDisclaimer/DebugDisclaimer";
import { AnimatePresence } from "framer-motion";
import SelectionOverlay from "./components/SelectionBoxDesktop/SelectionOverlay";
import AuthIframe from "./components/AuthIframe/AuthIframe";
import { useNotification } from "components/UI/NotificationProvider";
import AlertModalTokenExpire from "components/UI/AlertModalTokenExpire";
import { Button } from "antd";

const SynkOSv2: React.FC = observer(() => {
  const { backgroundUrl } = userStore;
  const notify = useNotification();
  const { t } = useTranslation();
  const auth0 = useAuth0();

  React.useEffect(() => {
    userStore.initializeAuth(auth0);
  }, [auth0.isAuthenticated]);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    if (e.button !== 0) return;

    e.preventDefault();

    selectionStore.startSelection(e.clientX, e.clientY);
  };

  if (userStore.isAuthenticated) {
    if (userStore.showPreboot) {
      return <Preboot />;
    } else {
      return (
        <div className="h-screen w-screen flex flex-col items-center relative overflow-hidden">
          {isImage(backgroundUrl) && (
            <div
              className="absolute w-full h-full bg-cover bg-center animate-fadeInWithBlur"
              style={{ backgroundImage: `url(${backgroundUrl})` }}
            />
          )}
          {isVideo(backgroundUrl) && (
            <video
              key={backgroundUrl}
              autoPlay
              loop
              muted
              className="absolute w-full h-full object-cover animate-fadeInWithBlur"
            >
              <source src={backgroundUrl} type="video/mp4" />
            </video>
          )}

          {userStore.debugMode && (
            <div className="absolute z-0 w-full pointer-events-none">
              <DebugDisclaimer />
            </div>
          )}

          <div
            className="absolute w-full h-full bg-customGray bg-opacity-20"
            onMouseDown={handleMouseDown}
          />

          {selectionStore.isSelecting && <SelectionOverlay />}

          <div className="absolute w-full flex justify-center pt-5">
            <TopBar />
          </div>

          <div className="relative w-full h-full">
            <AnimatePresence>
              {Array.from(appStore.runningApps.values()).map((appInstance) => (
                <Window
                  key={appInstance.appInstanceId}
                  appInstanceId={appInstance.appInstanceId}
                  title={appInstance.appName}
                  minimized={appInstance.minimized}
                  onClose={() =>
                    appStore.closeAppInstance(appInstance.appInstanceId)
                  }
                  onMinimize={() =>
                    appStore.minimizeAppInstance(appInstance.appInstanceId)
                  }
                  zIndex={appInstance.zIndex}
                  position={appInstance.position}
                  size={appInstance.windowSize}
                  onUpdatePositionAndSize={(position, windowSize, iframeSize) =>
                    appStore.updateAppPositionAndSize(
                      appInstance.appInstanceId,
                      position,
                      windowSize,
                      iframeSize
                    )
                  }
                  onClick={() =>
                    appStore.bringAppToFront(appInstance.appInstanceId)
                  }
                  windowType={appInstance.component ? "component" : "iframe"}
                  isLoading={appInstance.isLoading}
                  component={appInstance.component}
                  componentProps={appInstance.props}
                >
                  {appInstance.component ? null : appInstance.isLoading ||
                    !appInstance.screenUrl ? (
                    <div className="w-full h-full rounded-custom bg-gray-200 animate-pulse"></div>
                  ) : (
                    <AuthIframe
                      src={appInstance.screenUrl}
                      title={appInstance.appName}
                      className="w-full h-full border-none rounded-custom"
                      sandbox="allow-scripts allow-same-origin"
                      allow="camera; microphone; fullscreen"
                    />
                  )}
                </Window>
              ))}
            </AnimatePresence>
          </div>

          <Dock />

          {userStore.showAlertModal && (
            <AlertModalTokenExpire
              isOpen={userStore.showAlertModal}
              onClose={() => userStore.closeAlertModal()}
            />
          )}
        </div>
      );
    }
  } else {
    return <Login />;
  }
});

export default SynkOSv2;
