import React, { useEffect, useRef } from "react";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import isolatedAppStore from "./store/isolatedAppStore";
import { useParams, useLocation } from "react-router-dom";
import publicApiClient from "utils/publicApiClient";
import ExpiredSession from "./components/ExpiredSession";
import LoadingPage from "./components/LoadingPage";
import Header from "./components/Header";
import Sidebar from "./components/Sidebar";
import IframeComponent from "./components/IframeComponent";
import { PanelGroup, Panel, PanelResizeHandle } from "react-resizable-panels";
import { Drawer } from "vaul";
import { motion } from "framer-motion";
import DrawerContent from "./components/DrawerContent";
import { notificationManager } from "components/UI/notificationManager";
import axios from "axios";
import { useIsDesktop } from "utils/useIsDesktop";

const IsolatedApp: React.FC = observer(() => {
  const { sessionId } = useParams<{ sessionId: string }>();
  const location = useLocation();
  const effectRan = useRef(false);
  const isDesktop = useIsDesktop();

  useEffect(() => {
    if (isDesktop) {
      isolatedAppStore.isSidebarOpen = true;
    } else {
      isolatedAppStore.isSidebarOpen = false;
    }
  }, [isDesktop]);

  useEffect(() => {
    if (effectRan.current) return;
    effectRan.current = true;

    const queryParams = new URLSearchParams(location.search);
    const screen_url = queryParams.get("screen_url");

    if (sessionId && screen_url) {
      const decodedScreenUrl = decodeURIComponent(screen_url);

      isolatedAppStore.initialize(decodedScreenUrl);

      publicApiClient
        .get(`/v1/demo/${sessionId}/info`)
        .then((response) => {
          if (response.data && response.data.success) {
            const { runningUntil: newRunningUntil } = response.data.details;

            isolatedAppStore.updateRunningUntil(newRunningUntil);

            runInAction(() => {
              isolatedAppStore.setProgressTo70();
            });

            checkTimeUpdate();
          } else {
            isolatedAppStore.remainingTime = 0;
            isolatedAppStore.isLoading = false;
          }
        })
        .catch((error) => {
          if (
            axios.isAxiosError(error) &&
            (error.response?.status === 429 ||
              error.response?.data?.error?.includes("Rate limit exceeded"))
          ) {
            isolatedAppStore.remainingTime = 0;
            isolatedAppStore.isLoading = false;
          } else {
            console.error("Error fetching demo info:", error);

            isolatedAppStore.remainingTime = 0;
            isolatedAppStore.isLoading = false;
          }
        });

      const checkTimeUpdate = () => {
        if (isolatedAppStore.remainingTime !== null) {
          const timeUntilCheck = (isolatedAppStore.remainingTime - 15) * 1000;
          if (timeUntilCheck > 0) {
            setTimeout(() => {
              publicApiClient
                .get(`/v1/demo/${sessionId}/info`)
                .then((response) => {
                  if (response.data && response.data.success) {
                    const { runningUntil: newRunningUntil } =
                      response.data.details;
                    if (
                      newRunningUntil !==
                      isolatedAppStore.runningUntil?.toISOString()
                    ) {
                      isolatedAppStore.updateRunningUntil(newRunningUntil);
                    }
                  } else {
                    isolatedAppStore.remainingTime = 0;
                    isolatedAppStore.isLoading = false;
                  }
                })
                .catch((error: any) => {
                  if (
                    axios.isAxiosError(error) &&
                    error.response?.status === 429
                  ) {
                    if (
                      isolatedAppStore.remainingTime !== null &&
                      isolatedAppStore.remainingTime <= 0
                    ) {
                      notificationManager.notify({
                        message: "Error",
                        description: error.response.data.error,
                        type: "error",
                      });
                      isolatedAppStore.remainingTime = 0;
                      isolatedAppStore.isLoading = false;
                    }
                  } else {
                    console.error("Error fetching demo info:", error);
                  }
                });
            }, timeUntilCheck);
          }
        }
      };
    } else {
      isolatedAppStore.remainingTime = 0;
      isolatedAppStore.isLoading = false;
    }
  }, [sessionId, location.search]);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 768) {
        isolatedAppStore.isSidebarOpen = true;
      } else {
        isolatedAppStore.isSidebarOpen = false;
      }
    };

    handleResize();

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      {isolatedAppStore.remainingTime !== null &&
      isolatedAppStore.remainingTime <= 0 ? (
        <ExpiredSession />
      ) : (
        <div
          className="h-[100svh] w-screen flex flex-col relative overflow-hidden"
          style={{
            background: "linear-gradient(180deg,#00FFD1,#14467D) 100vw 100vh",
            zIndex: 0,
            filter: `brightness(${isolatedAppStore.brightness})`,
          }}
        >
          {isolatedAppStore.isLoading && (
            <div className="absolute inset-0 z-50">
              <LoadingPage />
            </div>
          )}
          <div
            className="z-1 absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
            style={{
              backgroundImage: 'url("/images/Ellipse1.png")',
              zIndex: 1,
            }}
          ></div>

          <div
            className="z-3 absolute inset-0 bg-customGray/80 backdrop-blur-dock"
            style={{ zIndex: 3 }}
          ></div>
          <Header />

          <div className="relative z-40 pl-1.5 flex flex-1 overflow-hidden min-h-0">
            <Drawer.Root
              open={isolatedAppStore.isDrawerOpen}
              onOpenChange={isolatedAppStore.toggleDrawer}
              direction="left"
            >
              <Drawer.Trigger asChild>
                <motion.div className="absolute left-0 top-1/2 transform -translate-y-1/2 h-[80px] w-4 bg-gray-200 bg-opacity-50 backdrop-blur-sm z-50 cursor-pointer rounded-full rounded-l-lg flex items-center justify-center">
                  <div className="w-1 h-12 rounded-full bg-white bg-opacity-80 backdrop-blur-sm"></div>
                </motion.div>
              </Drawer.Trigger>
              <Drawer.Portal>
                <Drawer.Overlay className="fixed inset-0 z-40 bg-black bg-opacity-50" />
                <Drawer.Content className="bg-customGray/60 backdrop-blur-dock flex flex-col rounded-t-[10px] h-full w-[270px] fixed bottom-0 left-0 z-50">
                  <div className="p-4 flex-1 h-full">
                    <DrawerContent />
                  </div>
                </Drawer.Content>
              </Drawer.Portal>
            </Drawer.Root>

            <PanelGroup
              autoSaveId="conditional"
              direction="horizontal"
              className="flex flex-1"
            >
              {isolatedAppStore.isSidebarOpen && (
                <>
                  <Panel
                    defaultSize={22}
                    minSize={12}
                    maxSize={30}
                    id="left"
                    order={1}
                    className="h-full relative min-w-[200px]"
                  >
                    <Sidebar />
                  </Panel>
                  <PanelResizeHandle className="h-[98%] w-1 cursor-col-resize data-[resize-handle-active]:bg-white/20 data-[resize-handle-active]:backdrop-blur-custom hover:bg-white/20 hover:backdrop-blur-custom" />
                </>
              )}
              <Panel id="center" order={2} className="flex-1">
                <IframeComponent />
              </Panel>
            </PanelGroup>
          </div>
        </div>
      )}
    </>
  );
});

export default IsolatedApp;
