import React, { useEffect, useState, useRef, useCallback } from "react";
import { Rnd, RndDragCallback, RndResizeCallback } from "react-rnd";
import { ScrollArea } from "components/UI/Scroll-Area";
import { useTranslation } from "react-i18next";
import clsx from "clsx";
import WindowTopBar from "./WindowTopBar";
import { observer } from "mobx-react-lite";
import appStore from "stores/AppStore";
import userStore from "stores/UserStore";
import { motion, AnimatePresence } from "framer-motion";
import selectionStore from "stores/SelectionStore";
import { notificationManager } from "components/UI/notificationManager";

const windowVariants = {
  initial: { opacity: 0, scale: 0.9 },
  animate: { opacity: 1, scale: 1, x: 0, y: 0 },
  exit: { opacity: 0, scale: 0.9 },
  minimized: (custom: { x: number; y: number }) => ({
    scale: 0.1,
    opacity: 0,
    x: custom.x,
    y: custom.y,
    transition: { duration: 0.5, ease: "easeInOut" },
  }),
};

interface WindowProps {
  appInstanceId: string;
  title: string;
  minimized: boolean;
  onClose: () => void;
  onMinimize?: () => void;
  children?: React.ReactNode;
  windowType?: "iframe" | "component";
  zIndex: number;
  onClick: () => void;
  position: { x: number; y: number };
  size: { width: number; height: number };
  isLoading?: boolean;
  onUpdatePositionAndSize: (
    position: { x: number; y: number },
    windowSize: { width: number; height: number },
    iframeSize: { width: number; height: number }
  ) => void;
  component?: React.ComponentType;
  componentProps?: any;
}

const Window: React.FC<WindowProps> = ({
  appInstanceId,
  title,
  minimized,
  onClose,
  onMinimize,
  children,
  windowType = "component",
  zIndex,
  onClick,
  position: initialPosition,
  size: initialSize,
  isLoading,
  onUpdatePositionAndSize,
  component,
  componentProps,
}) => {
  const appInstance = appStore.runningApps.get(appInstanceId);
  const isMinimizable = appInstance?.isMinimizable !== false;
  const isMaximized = appInstance?.isMaximized || false;
  const edgeThreshold = 2;

  const rndRef = useRef<Rnd>(null);
  const titleBarRef = useRef<HTMLDivElement>(null);

  const { t } = useTranslation();

  const [isClosing, setIsClosing] = useState(false);
  const [isMinimizing, setIsMinimizing] = useState(false);
  const [maxSize, setMaxSize] = useState<{ width: number; height: number }>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const currentPositionRef = useRef<{ x: number; y: number }>(initialPosition);
  const currentWindowSizeRef = useRef<{ width: number; height: number }>(
    initialSize
  );
  const currentIframeSizeRef = useRef<{ width: number; height: number }>({
    width: initialSize.width,
    height: initialSize.height - 40,
  });

  const prevPositionRef = useRef<{ x: number; y: number }>(initialPosition);
  const prevWindowSizeRef = useRef<{ width: number; height: number }>(
    initialSize
  );

  const [snapSide, setSnapSide] = useState<"left" | "right" | null>(null);

  const [delta, setDelta] = useState<{ x: number; y: number }>({ x: 0, y: 0 });

  useEffect(() => {
    const handleResize = () => {
      const newWidth = window.innerWidth;
      const newHeight = window.innerHeight;
      setMaxSize({ width: newWidth, height: newHeight });

      if (appInstance?.isMaximized && rndRef.current) {
        rndRef.current.updateSize({
          width: newWidth,
          height: newHeight,
        });
        rndRef.current.updatePosition({ x: 0, y: 0 });

        currentWindowSizeRef.current = { width: newWidth, height: newHeight };
        currentPositionRef.current = { x: 0, y: 0 };
        currentIframeSizeRef.current = {
          width: newWidth,
          height: newHeight - (titleBarRef.current?.offsetHeight || 40),
        };

        if (
          prevWindowSizeRef.current.width !== newWidth ||
          prevWindowSizeRef.current.height !== newHeight ||
          prevPositionRef.current.x !== 0 ||
          prevPositionRef.current.y !== 0
        ) {
          prevWindowSizeRef.current = { width: newWidth, height: newHeight };
          prevPositionRef.current = { x: 0, y: 0 };

          onUpdatePositionAndSize(
            { x: 0, y: 0 },
            { width: newWidth, height: newHeight },
            {
              width: newWidth,
              height: newHeight - (titleBarRef.current?.offsetHeight || 40),
            }
          );
        }
      } else {
        const currentSize = currentWindowSizeRef.current;
        let adjustedSize = { ...currentSize };
        let adjusted = false;

        if (currentSize.width > newWidth) {
          adjustedSize.width = newWidth;
          adjusted = true;
        }
        if (currentSize.height > newHeight) {
          adjustedSize.height = newHeight;
          adjusted = true;
        }

        const clampedPosition = {
          x: Math.min(
            currentPositionRef.current.x,
            newWidth - adjustedSize.width
          ),
          y: Math.min(
            currentPositionRef.current.y,
            newHeight - adjustedSize.height
          ),
        };

        if (rndRef.current) {
          if (adjusted) {
            rndRef.current.updateSize(adjustedSize);
            currentWindowSizeRef.current = adjustedSize;
          }
          rndRef.current.updatePosition(clampedPosition);
          currentPositionRef.current = clampedPosition;
        }

        if (
          prevWindowSizeRef.current.width !==
            currentWindowSizeRef.current.width ||
          prevWindowSizeRef.current.height !==
            currentWindowSizeRef.current.height ||
          prevPositionRef.current.x !== currentPositionRef.current.x ||
          prevPositionRef.current.y !== currentPositionRef.current.y
        ) {
          prevWindowSizeRef.current = { ...currentWindowSizeRef.current };
          prevPositionRef.current = { ...currentPositionRef.current };

          onUpdatePositionAndSize(
            currentPositionRef.current,
            currentWindowSizeRef.current,
            currentIframeSizeRef.current
          );

          appStore.setCustomSize(appInstanceId, currentWindowSizeRef.current);
          appStore.setCustomPosition(appInstanceId, currentPositionRef.current);
        }
      }
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [appInstance?.isMaximized, appInstanceId, onUpdatePositionAndSize]);

  useEffect(() => {
    const initialClampedWidth = Math.min(initialSize.width, maxSize.width);
    const initialClampedHeight = Math.min(initialSize.height, maxSize.height);
    const initialClampedPosition = {
      x: Math.min(initialPosition.x, maxSize.width - initialClampedWidth),
      y: Math.min(initialPosition.y, maxSize.height - initialClampedHeight),
    };

    if (
      initialClampedWidth !== initialSize.width ||
      initialClampedHeight !== initialSize.height ||
      initialClampedPosition.x !== initialPosition.x ||
      initialClampedPosition.y !== initialPosition.y
    ) {
      if (rndRef.current) {
        rndRef.current.updateSize({
          width: initialClampedWidth,
          height: initialClampedHeight,
        });
        rndRef.current.updatePosition(initialClampedPosition);
      }

      currentWindowSizeRef.current = {
        width: initialClampedWidth,
        height: initialClampedHeight,
      };
      currentPositionRef.current = initialClampedPosition;
      currentIframeSizeRef.current = {
        width: initialClampedWidth,
        height:
          initialClampedHeight - (titleBarRef.current?.offsetHeight || 40),
      };

      if (
        prevWindowSizeRef.current.width !== initialClampedWidth ||
        prevWindowSizeRef.current.height !== initialClampedHeight ||
        prevPositionRef.current.x !== initialClampedPosition.x ||
        prevPositionRef.current.y !== initialClampedPosition.y
      ) {
        prevWindowSizeRef.current = {
          width: initialClampedWidth,
          height: initialClampedHeight,
        };
        prevPositionRef.current = initialClampedPosition;

        onUpdatePositionAndSize(
          initialClampedPosition,
          { width: initialClampedWidth, height: initialClampedHeight },
          {
            width: initialClampedWidth,
            height:
              initialClampedHeight - (titleBarRef.current?.offsetHeight || 40),
          }
        );

        appStore.setCustomSize(appInstanceId, {
          width: initialClampedWidth,
          height: initialClampedHeight,
        });
        appStore.setCustomPosition(appInstanceId, initialClampedPosition);
      }
    }
  }, [
    initialSize,
    initialPosition,
    maxSize,
    appInstanceId,
    onUpdatePositionAndSize,
  ]);

  useEffect(() => {
    if (minimized) {
      setIsMinimizing(true);

      const dockX =
        window.innerWidth / 2 - currentWindowSizeRef.current.width / 2;
      const dockY = window.innerHeight - 25;

      const deltaX = dockX - currentPositionRef.current.x;
      const deltaY = dockY - currentPositionRef.current.y;

      setDelta({ x: deltaX, y: deltaY });

      setTimeout(() => {
        setIsMinimizing(false);
      }, 500);
    } else {
      setDelta({ x: 0, y: 0 });
    }
  }, [minimized]);

  useEffect(() => {
    const adjustWindowSize = () => {
      const maxWidth = window.innerWidth;
      const maxHeight = window.innerHeight;
      const clampedWidth = Math.min(
        currentWindowSizeRef.current.width,
        maxWidth
      );
      const clampedHeight = Math.min(
        currentWindowSizeRef.current.height,
        maxHeight
      );
      const clampedPosition = {
        x: Math.min(currentPositionRef.current.x, maxWidth - clampedWidth),
        y: Math.min(currentPositionRef.current.y, maxHeight - clampedHeight),
      };

      if (rndRef.current) {
        rndRef.current.updateSize({
          width: clampedWidth,
          height: clampedHeight,
        });
        rndRef.current.updatePosition(clampedPosition);
      }

      currentWindowSizeRef.current = {
        width: clampedWidth,
        height: clampedHeight,
      };
      currentPositionRef.current = clampedPosition;
      currentIframeSizeRef.current = {
        width: clampedWidth,
        height: clampedHeight - (titleBarRef.current?.offsetHeight || 40),
      };

      if (
        prevWindowSizeRef.current.width !== clampedWidth ||
        prevWindowSizeRef.current.height !== clampedHeight ||
        prevPositionRef.current.x !== clampedPosition.x ||
        prevPositionRef.current.y !== clampedPosition.y
      ) {
        prevWindowSizeRef.current = {
          width: clampedWidth,
          height: clampedHeight,
        };
        prevPositionRef.current = clampedPosition;

        onUpdatePositionAndSize(
          clampedPosition,
          { width: clampedWidth, height: clampedHeight },
          {
            width: clampedWidth,
            height: clampedHeight - (titleBarRef.current?.offsetHeight || 40),
          }
        );

        appStore.setCustomSize(appInstanceId, {
          width: clampedWidth,
          height: clampedHeight,
        });
        appStore.setCustomPosition(appInstanceId, clampedPosition);
      }
    };

    window.addEventListener("resize", adjustWindowSize);
    return () => window.removeEventListener("resize", adjustWindowSize);
  }, [appInstance?.isMaximized, appInstanceId, onUpdatePositionAndSize]);

  const handleMaximize = useCallback(() => {
    try {
      if (rndRef.current) {
        if (appInstance?.isMaximized) {
          const sizeToRestore = appInstance.customWindowSize || initialSize;
          const positionToRestore =
            appInstance.customPosition || initialPosition;

          const clampedWidth = Math.min(sizeToRestore.width, maxSize.width);
          const clampedHeight = Math.min(sizeToRestore.height, maxSize.height);
          const adjustedSize = { width: clampedWidth, height: clampedHeight };

          rndRef.current.updateSize(adjustedSize);
          rndRef.current.updatePosition(positionToRestore);

          currentWindowSizeRef.current = adjustedSize;
          currentPositionRef.current = positionToRestore;
          currentIframeSizeRef.current = {
            width: adjustedSize.width,
            height:
              adjustedSize.height - (titleBarRef.current?.offsetHeight || 40),
          };

          if (
            prevWindowSizeRef.current.width !== adjustedSize.width ||
            prevWindowSizeRef.current.height !== adjustedSize.height ||
            prevPositionRef.current.x !== positionToRestore.x ||
            prevPositionRef.current.y !== positionToRestore.y
          ) {
            prevWindowSizeRef.current = adjustedSize;
            prevPositionRef.current = positionToRestore;

            onUpdatePositionAndSize(positionToRestore, adjustedSize, {
              width: adjustedSize.width,
              height:
                adjustedSize.height - (titleBarRef.current?.offsetHeight || 40),
            });

            appStore.setIsMaximized(appInstanceId, false);
          }
        } else {
          if (!appInstance?.isMaximized) {
            appStore.setCustomSize(appInstanceId, currentWindowSizeRef.current);
            appStore.setCustomPosition(
              appInstanceId,
              currentPositionRef.current
            );
          }

          const maximizedWidth = maxSize.width;
          const maximizedHeight = maxSize.height;

          rndRef.current.updateSize({
            width: maximizedWidth,
            height: maximizedHeight,
          });
          rndRef.current.updatePosition({ x: 0, y: 0 });

          currentWindowSizeRef.current = {
            width: maximizedWidth,
            height: maximizedHeight,
          };
          currentPositionRef.current = { x: 0, y: 0 };
          currentIframeSizeRef.current = {
            width: maximizedWidth,
            height: maximizedHeight - (titleBarRef.current?.offsetHeight || 40),
          };

          if (
            prevWindowSizeRef.current.width !== maximizedWidth ||
            prevWindowSizeRef.current.height !== maximizedHeight ||
            prevPositionRef.current.x !== 0 ||
            prevPositionRef.current.y !== 0
          ) {
            prevWindowSizeRef.current = {
              width: maximizedWidth,
              height: maximizedHeight,
            };
            prevPositionRef.current = { x: 0, y: 0 };

            onUpdatePositionAndSize(
              { x: 0, y: 0 },
              { width: maximizedWidth, height: maximizedHeight },
              {
                width: maximizedWidth,
                height:
                  maximizedHeight - (titleBarRef.current?.offsetHeight || 40),
              }
            );

            appStore.setIsMaximized(appInstanceId, true);
          }
        }
      }
    } catch (error) {
      userStore.debugMode
        ? console.error("Erreur lors du basculement plein écran :", error)
        : notificationManager.notify({
            message: t("error"),
            description: t("error_occured"),
            duration: 5,
          });
    }
  }, [
    appInstance,
    appInstanceId,
    initialSize,
    initialPosition,
    maxSize,
    onUpdatePositionAndSize,
  ]);

  const handleRestoreSize = useCallback(() => {
    if (rndRef.current) {
      const defaultSize = appInstance?.defaultWindowSize || initialSize;
      const defaultPosition = appInstance?.defaultPosition || initialPosition;

      rndRef.current.updateSize(defaultSize);
      rndRef.current.updatePosition(defaultPosition);

      currentWindowSizeRef.current = defaultSize;
      currentPositionRef.current = defaultPosition;
      currentIframeSizeRef.current = {
        width: defaultSize.width,
        height: defaultSize.height - (titleBarRef.current?.offsetHeight || 40),
      };

      if (
        prevWindowSizeRef.current.width !== defaultSize.width ||
        prevWindowSizeRef.current.height !== defaultSize.height ||
        prevPositionRef.current.x !== defaultPosition.x ||
        prevPositionRef.current.y !== defaultPosition.y
      ) {
        prevWindowSizeRef.current = defaultSize;
        prevPositionRef.current = defaultPosition;

        onUpdatePositionAndSize(defaultPosition, defaultSize, {
          width: defaultSize.width,
          height:
            defaultSize.height - (titleBarRef.current?.offsetHeight || 40),
        });

        appStore.setCustomSize(appInstanceId, defaultSize);
        appStore.setCustomPosition(appInstanceId, defaultPosition);
      }
    }
  }, [
    appInstance?.defaultWindowSize,
    appInstance?.defaultPosition,
    initialSize,
    initialPosition,
    appInstanceId,
    onUpdatePositionAndSize,
  ]);

  const handleMinimize = useCallback(() => {
    try {
      if (onMinimize) {
        onMinimize();
      }
    } catch (error) {
      userStore.debugMode
        ? console.error("Erreur lors de la minimisation :", error)
        : notificationManager.notify({
            message: t("error"),
            description: t("error_occured"),
            duration: 5,
          });
    }
  }, [onMinimize]);

  const handleClose = useCallback(() => {
    try {
      setIsClosing(true);
    } catch (error) {
      userStore.debugMode
        ? console.error("Erreur lors de la fermeture :", error)
        : notificationManager.notify({
            message: t("error"),
            description: t("error_occured"),
            duration: 5,
          });
    }
  }, []);

  useEffect(() => {
    if (isClosing) {
      setTimeout(() => {
        onClose();
      }, 500);
    }
  }, [isClosing, onClose]);

  const updateSizes = useCallback(() => {
    const windowWidth =
      rndRef.current?.resizableElement.current?.offsetWidth || 0;
    const windowHeight =
      rndRef.current?.resizableElement.current?.offsetHeight || 0;
    const titleBarHeight = titleBarRef.current?.offsetHeight || 40;
    const iframeWidth = windowWidth;
    const iframeHeight = windowHeight - titleBarHeight;

    currentWindowSizeRef.current = {
      width: windowWidth,
      height: windowHeight,
    };
    currentIframeSizeRef.current = {
      width: iframeWidth,
      height: iframeHeight,
    };
  }, []);

  const handleResize: RndResizeCallback = useCallback(
    (e, direction, ref, delta, position) => {
      currentPositionRef.current = { x: position.x, y: position.y };
      updateSizes();
    },
    [updateSizes]
  );

  const handleResizeStop: RndResizeCallback = useCallback(
    (e, direction, ref, delta, position) => {
      currentPositionRef.current = { x: position.x, y: position.y };
      updateSizes();

      const clampedWidth = Math.min(
        currentWindowSizeRef.current.width,
        maxSize.width
      );
      const clampedHeight = Math.min(
        currentWindowSizeRef.current.height,
        maxSize.height
      );
      const adjustedSize = {
        width: clampedWidth,
        height: clampedHeight,
      };

      if (
        clampedWidth !== currentWindowSizeRef.current.width ||
        clampedHeight !== currentWindowSizeRef.current.height
      ) {
        rndRef.current?.updateSize(adjustedSize);
        currentWindowSizeRef.current = adjustedSize;
      }

      if (
        prevWindowSizeRef.current.width !==
          currentWindowSizeRef.current.width ||
        prevWindowSizeRef.current.height !==
          currentWindowSizeRef.current.height ||
        prevPositionRef.current.x !== currentPositionRef.current.x ||
        prevPositionRef.current.y !== currentPositionRef.current.y
      ) {
        prevWindowSizeRef.current = { ...currentWindowSizeRef.current };
        prevPositionRef.current = { ...currentPositionRef.current };

        onUpdatePositionAndSize(
          currentPositionRef.current,
          currentWindowSizeRef.current,
          currentIframeSizeRef.current
        );

        appStore.setCustomSize(appInstanceId, currentWindowSizeRef.current);
        appStore.setCustomPosition(appInstanceId, currentPositionRef.current);
      }
    },
    [maxSize, appInstanceId, updateSizes, onUpdatePositionAndSize]
  );

  const handleDrag: RndDragCallback = useCallback((e, d) => {
    currentPositionRef.current = { x: d.x, y: d.y };

    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      setSnapSide(null);
      return;
    }

    const windowWidth = currentWindowSizeRef.current.width;
    const positionX = currentPositionRef.current.x;

    const screenWidth = window.innerWidth;

    if (positionX <= edgeThreshold) {
      setSnapSide("left");
    } else if (positionX + windowWidth >= screenWidth - edgeThreshold) {
      setSnapSide("right");
    } else {
      setSnapSide(null);
    }
  }, []);

  const handleDragStop: RndDragCallback = useCallback(
    (e, d) => {
      currentPositionRef.current = { x: d.x, y: d.y };

      const didSnap = checkForSnap();

      setSnapSide(null);

      if (didSnap) {
        return;
      }

      if (
        prevPositionRef.current.x !== currentPositionRef.current.x ||
        prevPositionRef.current.y !== currentPositionRef.current.y
      ) {
        prevPositionRef.current = { ...currentPositionRef.current };

        onUpdatePositionAndSize(
          currentPositionRef.current,
          currentWindowSizeRef.current,
          currentIframeSizeRef.current
        );

        if (!appInstance?.isMaximized) {
          appStore.setCustomPosition(appInstanceId, currentPositionRef.current);
        }
      }
    },
    [appInstance, appInstanceId, onUpdatePositionAndSize]
  );

  const checkForSnap = useCallback(() => {
    const isMobile = window.innerWidth <= 768;

    if (isMobile) {
      return false;
    }

    const windowWidth = currentWindowSizeRef.current.width;
    const positionX = currentPositionRef.current.x;

    const screenWidth = window.innerWidth;

    if (positionX <= edgeThreshold) {
      const newWidth = screenWidth / 2;
      const newHeight = window.innerHeight;
      const newX = 0;
      const newY = 0;

      snapWindow(newX, newY, newWidth, newHeight);

      return true;
    } else if (positionX + windowWidth >= screenWidth - edgeThreshold) {
      const newWidth = screenWidth / 2;
      const newHeight = window.innerHeight;
      const newX = screenWidth / 2;
      const newY = 0;

      snapWindow(newX, newY, newWidth, newHeight);

      return true;
    }
    return false;
  }, []);

  const snapWindow = useCallback(
    (newX: number, newY: number, newWidth: number, newHeight: number) => {
      if (rndRef.current) {
        rndRef.current.updateSize({ width: newWidth, height: newHeight });
        rndRef.current.updatePosition({ x: newX, y: newY });
      }

      currentWindowSizeRef.current = { width: newWidth, height: newHeight };
      currentPositionRef.current = { x: newX, y: newY };
      currentIframeSizeRef.current = {
        width: newWidth,
        height: newHeight - (titleBarRef.current?.offsetHeight || 40),
      };

      prevWindowSizeRef.current = { ...currentWindowSizeRef.current };
      prevPositionRef.current = { ...currentPositionRef.current };

      onUpdatePositionAndSize(
        currentPositionRef.current,
        currentWindowSizeRef.current,
        currentIframeSizeRef.current
      );

      appStore.setCustomSize(appInstanceId, currentWindowSizeRef.current);
      appStore.setCustomPosition(appInstanceId, currentPositionRef.current);
    },
    [appInstanceId, onUpdatePositionAndSize]
  );

  const renderSnapIndicator = () => {
    if (!snapSide) return null;

    const indicatorClasses = clsx(
      "fixed top-0 bottom-0 w-1/2 bg-customGray/30 m-1 pointer-events-none rounded-custom backdrop-blur-buttons shadow-custom",
      {
        "left-0": snapSide === "left",
        "right-0": snapSide === "right",
      }
    );

    return <div className={indicatorClasses} />;
  };

  const windowClasses = clsx("window-component", {
    "window-maximized": appInstance?.isMaximized,
    "window-closing": isClosing,
    "pointer-events-none": selectionStore.isSelecting,
  });

  const cursorClass = appInstance?.isMaximized
    ? "cursor-default"
    : "cursor-move";

  const resizeHandleComponents = {
    top: <div className="resize-handle resize-handle-top" />,
    right: <div className="resize-handle resize-handle-right" />,
    bottom: <div className="resize-handle resize-handle-bottom" />,
    left: <div className="resize-handle resize-handle-left" />,
    topRight: <div className="resize-handle resize-handle-top-right" />,
    topLeft: <div className="resize-handle resize-handle-top-left" />,
    bottomRight: <div className="resize-handle resize-handle-bottom-right" />,
    bottomLeft: <div className="resize-handle resize-handle-bottom-left" />,
  };

  const getAnimationState = () => {
    if (isClosing) {
      return "exit";
    }
    if (isMinimizing) {
      return "minimized";
    }
    return "animate";
  };

  return (
    <>
      {renderSnapIndicator()}
      <AnimatePresence>
        {(!minimized || isMinimizing) && !isClosing && (
          <Rnd
            key={appInstanceId}
            ref={rndRef}
            default={{
              x: initialPosition.x,
              y: initialPosition.y,
              width: initialSize.width,
              height: initialSize.height,
            }}
            onDrag={handleDrag}
            onDragStop={handleDragStop}
            onResize={handleResize}
            onResizeStop={handleResizeStop}
            enableResizing={!appInstance?.isMaximized}
            disableDragging={appInstance?.isMaximized}
            bounds="window"
            minWidth={320}
            minHeight={400}
            maxWidth={maxSize.width}
            maxHeight={maxSize.height}
            className={windowClasses}
            style={{ zIndex }}
            onMouseDown={onClick}
            resizeHandleComponent={resizeHandleComponents}
          >
            <motion.div
              variants={windowVariants}
              initial="initial"
              animate={getAnimationState()}
              exit="exit"
              custom={{ x: delta.x, y: delta.y }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              onAnimationComplete={() => {
                if (isMinimizing && minimized) {
                  setIsMinimizing(false);
                }
                if (isClosing) {
                  onClose();
                }
              }}
              className={clsx(
                "relative flex flex-col h-full w-full box-border",
                "bg-customGray/30 rounded-custom backdrop-blur-dock shadow-custom",

                cursorClass
              )}
            >
              <WindowTopBar
                title={title}
                isMaximized={isMaximized}
                onClose={handleClose}
                onMinimize={isMinimizable ? handleMinimize : undefined}
                onMaximize={handleMaximize}
                id={appInstanceId}
                isMinimizable={isMinimizable}
                onRestoreSize={handleRestoreSize}
                appLogoUrl={appInstance?.appLogoUrl}
                // vpnStatus={appInstance?.vpn}
                vpnStatus={
                  windowType === "iframe" ? appInstance?.vpn : undefined
                }
              />

              {component ? (
                appInstance?.noScrollArea ? (
                  <div className="flex-grow overflow-hidden w-full h-full pt-0 px-4 pb-4 box-border">
                    {React.createElement(component, componentProps)}
                  </div>
                ) : (
                  <ScrollArea viewportClassName="flex-grow overflow-hidden w-full h-full pt-0 px-4 pb-4 box-border">
                    {React.createElement(component, componentProps)}
                  </ScrollArea>
                )
              ) : windowType === "iframe" ? (
                <div className="flex-grow overflow-hidden w-full h-full pt-0 px-4 pb-4 box-border">
                  {isLoading ? (
                    <div className="w-full h-full bg-gray-200 animate-pulse rounded-custom"></div>
                  ) : (
                    children
                  )}
                </div>
              ) : (
                <ScrollArea className="flex-grow overflow-hidden w-full h-full pt-0 px-4 pb-4 box-border">
                  {children}
                </ScrollArea>
              )}
            </motion.div>
          </Rnd>
        )}
      </AnimatePresence>
    </>
  );
};

export default observer(Window);
