import { makeAutoObservable, action, runInAction } from "mobx";

class IsolatedAppStore {
  isSidebarOpen = false;
  isDrawerOpen = false;
  remainingTime: number | null = null;
  connected = false;
  isVolumeMuted = false;
  isMicMuted = false;
  isWebcamEnabled = true;
  hasExtended = false;
  isFullscreen = false;
  brightness = 1;
  screenUrl = "";
  runningUntil: Date | null = null;
  intervalId: NodeJS.Timeout | null = null;
  loadingProgressInterval: number | null = null;
  isLoading = true;
  loadingProgress = 0;
  delayAfterConnection = 1000;
  mediaStream: MediaStream | null = null;
  MAX_ATTEMPT_DURATION = 30 * 1000;
  ipAddress: string = "Loading...";
  sendClipboardText: (text: string) => void = () => {};

  constructor() {
    makeAutoObservable(this, {
      initialize: action.bound,
      setLoading: action.bound,
      setLoadingProgress: action.bound,
      startLoadingProgress: action.bound,
      stopLoadingProgress: action.bound,
      setProgressTo70: action.bound,
      incrementProgress: action.bound,
      startTimer: action.bound,
      calculateRemainingTime: action.bound,
      toggleSidebar: action.bound,
      toggleDrawer: action.bound,
      setConnected: action.bound,
      toggleVolume: action.bound,
      toggleMic: action.bound,
      toggleWebcam: action.bound,
      toggleFullscreen: action.bound,
      setBrightness: action.bound,
      fetchIpAddress: action.bound,
      updateRunningUntil: action.bound,
      setHasExtended: action.bound,
    });
  }

  updateRunningUntil = (newRunningUntil: string) => {
    this.runningUntil = new Date(newRunningUntil);
    this.calculateRemainingTime();
    this.startTimer();
  };

  setHasExtended = (value: boolean) => {
    this.hasExtended = value;
  };

  fetchIpAddress = async () => {
    try {
      const response = await fetch("https://api.ipify.org?format=json");
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const data = await response.json();
      runInAction(() => {
        this.ipAddress = data.ip;
      });
    } catch (error) {
      console.error("Error fetching IP address:", error);
      runInAction(() => {
        this.ipAddress = "Unavailable";
      });
    }
  };

  initialize = (screenUrl: string) => {
    this.screenUrl = screenUrl;
    this.fetchIpAddress();
    this.startLoadingProgress();
  };

  setLoading = (loading: boolean) => {
    this.isLoading = loading;
  };

  setLoadingProgress = (progress: number) => {
    this.loadingProgress = Math.floor(progress);
  };

  startLoadingProgress = () => {
    this.loadingProgress = 0;
    const targetProgress = 70;
    const totalDuration = 3500;
    const startTime = Date.now();

    const updateProgress = () => {
      const elapsed = Date.now() - startTime;
      const progress = Math.min(
        targetProgress,
        (elapsed / totalDuration) * targetProgress
      );

      runInAction(() => {
        this.setLoadingProgress(progress);
      });

      if (this.loadingProgress < targetProgress) {
        this.loadingProgressInterval = requestAnimationFrame(updateProgress);
      } else {
        this.stopLoadingProgress();
      }
    };

    updateProgress();
  };

  setProgressTo70 = () => {
    this.setLoadingProgress(70);
  };

  incrementProgress = (value: number) => {
    this.setLoadingProgress(this.loadingProgress + value);
  };

  stopLoadingProgress = () => {
    if (this.loadingProgressInterval !== null) {
      cancelAnimationFrame(this.loadingProgressInterval);
      this.loadingProgressInterval = null;
    }
  };

  startTimer = () => {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
    this.intervalId = setInterval(() => {
      this.calculateRemainingTime();
      if (
        this.remainingTime !== null &&
        this.remainingTime <= 0 &&
        this.intervalId
      ) {
        clearInterval(this.intervalId);
        this.intervalId = null;
      }
    }, 1000);
  };

  calculateRemainingTime = () => {
    if (this.runningUntil) {
      const now = new Date();
      const diff = (this.runningUntil.getTime() - now.getTime()) / 1000;
      this.remainingTime = Math.max(0, Math.floor(diff));
    } else {
      this.remainingTime = null;
    }
  };

  toggleSidebar = () => {
    this.isSidebarOpen = !this.isSidebarOpen;
  };

  toggleDrawer = () => {
    this.isDrawerOpen = !this.isDrawerOpen;
  };

  setConnected = (status: boolean) => {
    this.connected = status;
  };

  toggleVolume = () => {
    this.isVolumeMuted = !this.isVolumeMuted;
    const audios = document.querySelectorAll("audio, video");
    audios.forEach((media) => {
      (media as HTMLMediaElement).muted = this.isVolumeMuted;
    });
  };

  toggleMic = async () => {
    this.isMicMuted = !this.isMicMuted;
    if (!this.mediaStream) {
      await this.startMediaStream();
    }
    this.mediaStream?.getAudioTracks().forEach((track) => {
      track.enabled = !this.isMicMuted;
    });
  };

  toggleWebcam = async () => {
    this.isWebcamEnabled = !this.isWebcamEnabled;
    if (!this.mediaStream) {
      await this.startMediaStream();
    }
    this.mediaStream?.getVideoTracks().forEach((track) => {
      track.enabled = this.isWebcamEnabled;
    });
  };

  toggleFullscreen = () => {
    this.isFullscreen = !this.isFullscreen;
    if (this.isFullscreen) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if ((document.documentElement as any).webkitRequestFullscreen) {
        (document.documentElement as any).webkitRequestFullscreen();
      } else if ((document.documentElement as any).msRequestFullscreen) {
        (document.documentElement as any).msRequestFullscreen();
      }
      this.isSidebarOpen = false;
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if ((document as any).webkitExitFullscreen) {
        (document as any).webkitExitFullscreen();
      } else if ((document as any).msExitFullscreen) {
        (document as any).msExitFullscreen();
      }
    }
  };

  setBrightness = (value: number) => {
    this.brightness = value;
  };

  private startMediaStream = async () => {
    try {
      this.mediaStream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });
    } catch (error) {
      console.error("Error accessing media devices:", error);
    }
  };
}

const isolatedAppStore = new IsolatedAppStore();
export default isolatedAppStore;
