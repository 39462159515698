import React, {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  AnimatePresence,
  MotionValue,
  animate,
  motion,
  useAnimation,
  useMotionValue,
  useSpring,
  useTransform,
} from "framer-motion";
import { observer } from "mobx-react-lite";
import { Popover, PopoverTrigger, PopoverContent } from "@nextui-org/react";

import { cn } from "lib/utils";
import appStore, { RunningAppInstance } from "stores/AppStore";
import LinkPreview from "components/UI/link-preview";

interface DockContextType {
  width: number;
  hovered: boolean;
  setIsZooming: (value: boolean) => void;
  zoomLevel: MotionValue<number>;
  mouseX: MotionValue<number>;
  animatingIndexes: number[];
  setAnimatingIndexes: (indexes: number[]) => void;
  isPopoverOpen: boolean;
  setIsPopoverOpen: (value: boolean) => void;
}

const INITIAL_WIDTH = 48;

const DockContext = createContext<DockContextType>({
  width: 0,
  hovered: false,
  setIsZooming: () => {},
  zoomLevel: null as any,
  mouseX: null as any,
  animatingIndexes: [],
  setAnimatingIndexes: () => {},
  isPopoverOpen: false,
  setIsPopoverOpen: () => {},
});

const useDock = () => useContext(DockContext);

interface DockProps {
  className?: string;
  children: ReactNode;
}

function Dock({ className, children }: DockProps) {
  const [hovered, setHovered] = useState(false);
  const [width, setWidth] = useState(0);
  const dockRef = useRef<HTMLDivElement>(null);
  const isZooming = useRef(false);
  const [animatingIndexes, setAnimatingIndexes] = useState<number[]>([]);
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  const setIsZooming = useCallback((value: boolean) => {
    isZooming.current = value;
    setHovered(!value);
  }, []);

  const zoomLevel = useMotionValue(1);

  useWindowResize(() => {
    setWidth(dockRef.current?.clientWidth || 0);
  });

  const mouseX = useMotionValue(Infinity);

  useEffect(() => {
    if (isPopoverOpen) {
      mouseX.set(Infinity);
      setHovered(false);
    }
  }, [isPopoverOpen]);

  return (
    <DockContext.Provider
      value={{
        hovered,
        setIsZooming,
        width,
        zoomLevel,
        mouseX,
        animatingIndexes,
        setAnimatingIndexes,
        isPopoverOpen,
        setIsPopoverOpen,
      }}
    >
      <motion.div
        ref={dockRef}
        className={cn(
          "fixed bottom-6 left-1/2 transform -translate-x-1/2 flex items-end h-[72px] p-[8px] gap-3 rounded-xl",
          "bg-customGray/30 backdrop-blur-dock px-8 sm:px-20 no-underline shadow-custom rounded-full transition-colors hover:bg-lightColor/10",
          "z-50",
          className
        )}
        onMouseMove={(e) => {
          if (!isPopoverOpen) {
            mouseX.set(e.pageX);
            if (!isZooming.current) {
              setHovered(true);
            }
          }
        }}
        onMouseLeave={() => {
          if (!isPopoverOpen) {
            mouseX.set(Infinity);
            setHovered(false);
          }
        }}
        style={{
          x: "-50%",
          scale: zoomLevel,
        }}
      >
        {children}
      </motion.div>
    </DockContext.Provider>
  );
}

interface DockCardInnerProps {
  src: string;
  id: string;
  children?: ReactNode;
}

const DockCardInner = observer(({ src, id, children }: DockCardInnerProps) => {
  const { animatingIndexes } = useDock();

  return (
    <span className="relative flex justify-center items-center z-0 overflow-hidden w-full h-full rounded-md">
      <motion.img
        className="absolute z-10 opacity-60 filter blur-lg transform translate-y-2.5 scale-125"
        src={src}
        alt=""
      />

      <AnimatePresence>
        {animatingIndexes.includes(parseInt(id)) && children ? (
          <motion.div
            className="relative z-0 h-full w-full rounded-full"
            initial={{ scale: 0, opacity: 0, filter: "blur(4px)" }}
            animate={{
              scale: 1,
              opacity: 1,
              filter: "blur(0px)",
              transition: {
                type: "tween",
                duration: 0.3,
                ease: "linear",
                delay: 0.2,
              },
            }}
            exit={{
              scale: 0,
              opacity: 0,
              filter: "blur(4px)",
              transition: { duration: 0 },
            }}
          >
            <div className="h-full w-full flex flex-col items-center justify-center">
              {children}
            </div>
          </motion.div>
        ) : null}
      </AnimatePresence>

      <AnimatePresence mode="popLayout">
        {!animatingIndexes.includes(parseInt(id)) ? (
          <motion.img
            layoutId={id}
            className="relative z-0 w-1/2 h-1/2 rounded-full border-white/10"
            src={src}
            alt=""
          />
        ) : null}
      </AnimatePresence>
    </span>
  );
});

interface DockCardProps {
  children: React.ReactNode;
  id: string;
  onClick?: () => void;
  isRunning?: boolean;
  runningInstances?: RunningAppInstance[];
  appName: string;
}

const DockCard = observer(
  ({
    children,
    id,
    onClick,
    isRunning,
    runningInstances,
    appName,
  }: DockCardProps) => {
    const cardRef = useRef<HTMLButtonElement>(null);
    const [elCenterX, setElCenterX] = useState(0);
    const dock = useDock();

    const opacity = useSpring(0, {
      stiffness: 300,
      damping: 20,
    });

    const controls = useAnimation();

    const [isPopoverOpen, setIsPopoverOpen] = useState(false);

    useEffect(() => {
      if (isPopoverOpen) {
        controls.start({
          y: -24,
          transition: {
            repeat: Infinity,
            repeatType: "reverse",
            duration: 0.5,
          },
        });

        if (!dock.animatingIndexes.includes(parseInt(id))) {
          dock.setAnimatingIndexes([...dock.animatingIndexes, parseInt(id)]);
        }
      } else {
        controls.start({
          y: 0,
          transition: { duration: 0.5 },
        });

        if (dock.animatingIndexes.includes(parseInt(id))) {
          dock.setAnimatingIndexes(
            dock.animatingIndexes.filter((index) => index !== parseInt(id))
          );
        }
      }
    }, [isPopoverOpen]);

    useEffect(() => {
      if (isRunning) {
        opacity.set(1);
      } else {
        opacity.set(0);
      }
    }, [isRunning]);

    const handleCardClick = () => {
      if (runningInstances && runningInstances.length > 0) {
        setIsPopoverOpen(true);
        dock.setIsPopoverOpen(true);
      } else {
        if (onClick) {
          onClick();
          opacity.set(0.5);
          controls.start({
            y: -24,
            transition: {
              repeat: Infinity,
              repeatType: "reverse",
              duration: 0.5,
            },
          });
        }
      }
    };

    const handleNewInstance = async () => {
      setIsPopoverOpen(false);
      dock.setIsPopoverOpen(false);
      try {
        await appStore.launchApp(appName);
      } catch (error) {
        appStore.handleError(
          error,
          `Erreur lors du lancement d'une nouvelle instance de ${appName}.`
        );
      }
    };

    const handleInstanceClick = (instance: RunningAppInstance) => {
      try {
        if (instance.minimized) {
          appStore.restoreAppInstance(instance.appInstanceId);
        }
        appStore.bringAppToFront(instance.appInstanceId);
      } catch (error) {
        appStore.handleError(
          error,
          `Erreur lors de la restauration de l'instance ${instance.appInstanceId} de ${appName}.`
        );
      } finally {
        setIsPopoverOpen(false);
        dock.setIsPopoverOpen(false);
      }
    };

    const distance = useTransform(dock.mouseX, (val) => {
      const bounds = cardRef.current?.getBoundingClientRect() ?? {
        x: 0,
        width: 0,
      };
      return val - bounds.x - bounds.width / 2;
    });

    let widthSync = useTransform(distance, [-150, 0, 150], [55, 95, 55]);
    let width = useSpring(widthSync, {
      mass: 0.1,
      stiffness: 200,
      damping: 10,
    });

    useWindowResize(() => {
      const { x } = cardRef.current?.getBoundingClientRect() || { x: 0 };
      setElCenterX(x + INITIAL_WIDTH / 2);
    });

    return (
      <div className="flex flex-col items-center gap-2" key={id}>
        {runningInstances && runningInstances.length > 0 ? (
          <Popover
            isOpen={isPopoverOpen}
            onOpenChange={(open) => {
              setIsPopoverOpen(open);
              dock.setIsPopoverOpen(open);
            }}
            placement="top"
            offset={0}
            classNames={{
              base: "before:shadow-custom",
              content: [
                "p-2 overflow-auto max-h-[80vw]",
                "p-0 bg-lightColor/10  border-small border-divider shadow-custom relative",
              ],
            }}
            shouldFlip
            backdrop="opaque"
          >
            <PopoverTrigger>
              <motion.button
                ref={cardRef}
                className="rounded-lg aspect-square bg-neutral-800 saturate-90 brightness-90 transition-filter duration-200 hover:saturate-100 hover:brightness-112"
                style={{
                  width: width,
                }}
                animate={controls}
                whileTap={{ scale: 0.95 }}
              >
                {children}
              </motion.button>
            </PopoverTrigger>
            <PopoverContent className="z-50">
              <div className="flex flex-col p-2 box-border rounded-custom backdrop-blur-buttons bg-opacity-100">
                <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-2 lg:flex lg:flex-row lg:flex-wrap">
                  {runningInstances.map((instance) => (
                    <div
                      key={instance.appInstanceId}
                      className="cursor-pointer flex items-center justify-center p-2 rounded-lg hover:bg-gray-400 hover:bg-opacity-10 transition-colors duration-150 w-[150px] h-[100px] lg:w-[250px] lg:h-[150px]"
                      onClick={() => handleInstanceClick(instance)}
                    >
                      <LinkPreview
                        appInstance={instance}
                        className="font-bold"
                      />
                    </div>
                  ))}

                  <div
                    className="cursor-pointer flex items-center justify-center p-2 rounded-lg hover:bg-gray-400 hover:bg-opacity-10 transition-colors duration-150 w-[150px] h-[100px] lg:w-[250px] lg:h-[150px]"
                    onClick={handleNewInstance}
                  >
                    <span className="text-xl font-bold">+</span>
                  </div>
                </div>
              </div>
            </PopoverContent>
          </Popover>
        ) : (
          <motion.button
            ref={cardRef}
            className="rounded-lg aspect-square bg-neutral-800 saturate-90 brightness-90 transition-filter duration-200 hover:saturate-100 hover:brightness-112"
            onClick={handleCardClick}
            style={{
              width: width,
            }}
            animate={controls}
            whileTap={{ scale: 0.95 }}
          >
            {children}
          </motion.button>
        )}

        <AnimatePresence mode="popLayout">
          {isRunning && (
            <motion.div key={id} className="rounded-full" style={{ opacity }}>
              <motion.div
                exit={{ transition: { duration: 0 } }}
                className="w-1.5 h-1.5 rounded-full bg-white mb-1"
                style={{ opacity }}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    );
  }
);

function DockDivider() {
  return (
    <motion.div
      className="h-full flex items-center p-1.5 cursor-ns-resize"
      drag="y"
      dragConstraints={{ top: -100, bottom: 50 }}
    >
      <span className="w-0.5 h-full rounded bg-neutral-100/10"></span>
    </motion.div>
  );
}

type UseWindowResizeCallback = (width: number, height: number) => void;

function useWindowResize(callback: UseWindowResizeCallback) {
  const callbackRef = useCallbackRef(callback);

  useEffect(() => {
    const handleResize = () => {
      callbackRef(window.innerWidth, window.innerHeight);
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [callbackRef]);
}

function useCallbackRef<T extends (...args: any[]) => any>(callback: T): T {
  const callbackRef = useRef(callback);

  useEffect(() => {
    callbackRef.current = callback;
  });

  return useMemo(() => ((...args) => callbackRef.current?.(...args)) as T, []);
}

interface MousePositionOptions {
  onChange?: (position: { value: { x: number; y: number } }) => void;
}

export function useMousePosition(
  options: MousePositionOptions = {},
  deps: readonly any[] = []
) {
  const { onChange } = options;

  const x = useMotionValue(0);
  const y = useMotionValue(0);

  useEffect(() => {
    const handleMouseMove = (event: MouseEvent) => {
      animate(x, event.clientX);
      animate(y, event.clientY);
    };

    const handleChange = () => {
      if (onChange) {
        onChange({ value: { x: x.get(), y: y.get() } });
      }
    };

    const unsubscribeX = x.on("change", handleChange);
    const unsubscribeY = y.on("change", handleChange);

    window.addEventListener("mousemove", handleMouseMove);

    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
      unsubscribeX();
      unsubscribeY();
    };
  }, [x, y, onChange, ...deps]);

  return useMemo(
    () => ({
      x,
      y,
    }),
    [x, y]
  );
}

export { Dock, DockCard, DockCardInner, DockDivider, useDock };
