import React from "react";
import MessageGenericPage from "components/MessageGenericPage/MessageGenericPage";

const ErrorPage: React.FC = () => {
  return (
    <MessageGenericPage
      title={
        <>
          Yikes! <br /> That Didn't Go as Planned.
        </>
      }
      message="Looks like Synk tripped over itself. Maybe it needs a coffee break? Try again in a bit or give support a shout if it keeps acting up!"
      buttonText="Back to Synk"
    />
  );
};

export default ErrorPage;
