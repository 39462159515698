import React from "react";
import { observer } from "mobx-react-lite";
import { ShootingStars } from "components/UI/shooting-stars";
import { Spotlight } from "components/UI/Spotlight";
import { PulsatingButton } from "components/UI/PulsatingBtn";
import { useNavigate } from "react-router-dom";

interface MessageGenericPageProps {
  title: React.ReactNode;
  message: string;
  buttonText: string;
  backgroundImage?: string;
  className?: string;
}

const MessageGenericPage: React.FC<MessageGenericPageProps> = observer(
  ({
    title,
    message,
    buttonText,
    backgroundImage = "/images/Ellipse1.png",
    className = "",
  }) => {
    const navigate = useNavigate();

    const handleBackToSynk = () => {
      navigate("/");
    };

    return (
      <div
        className={`h-screen w-full rounded-md flex flex-col items-center justify-center bg-black/[0.96] antialiased bg-grid-white/[0.02] relative overflow-hidden ${className}`}
      >
        <div className="absolute inset-0 h-full w-full bg-[linear-gradient(to_right,#80808012_1px,transparent_1px),linear-gradient(to_bottom,#80808012_1px,transparent_1px)] bg-[size:44px_44px]"></div>

        <div className="absolute inset-0 bg-customGray animate-fadeOut z-0 pointer-events-none"></div>
        <div
          className="absolute inset-0 bg-cover bg-no-repeat animate-fadeInWithBlurDelayed opacity-0"
          style={{
            backgroundImage: `url("${backgroundImage}")`,
            zIndex: 0,
          }}
        ></div>
        <Spotlight
          className="-top-40 left-0 md:left-60 md:-top-20"
          fill="#22dcd1"
        />
        <div className="flex flex-col items-center p-4 px-8 max-w-7xl relative z-10">
          <h1 className="text-5xl md:text-7xl font-bold text-center bg-clip-text text-transparent bg-gradient-to-b from-synkColor/10 to-synkColor/50 py-1">
            {title}
          </h1>
          <p className="mt-4 font-normal text-base text-neutral-300 max-w-xl text-center">
            {message}
          </p>
          <div className="mt-6 flex justify-center">
            <PulsatingButton onClick={handleBackToSynk} pulseColor="#22dcd1">
              {buttonText}
            </PulsatingButton>
          </div>
        </div>

        <ShootingStars />
      </div>
    );
  }
);

export default MessageGenericPage;
