import React from "react";
import { BackgroundLines } from "components/UI/background-lines";
import { useTranslation } from "react-i18next";

const DebugWatermark: React.FC = () => {
  const { t } = useTranslation();

  return (
    <BackgroundLines className="flex items-center justify-center w-full flex-col px-4 pointer-events-none">
      <h2 className="bg-clip-text text-transparent text-center bg-gradient-to-b from-neutral-600 to-white text-2xl md:text-4xl lg:text-7xl font-sans py-2 md:py-10 relative z-20 font-bold tracking-tight">
        {t("debug_mode")}
      </h2>
    </BackgroundLines>
  );
};

export default DebugWatermark;
