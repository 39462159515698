"use client";

import { Tabs } from "components/UI/tabs";
import IdentityForm from "./components/forms/IdentityForm";
import PersonalityForm from "./components/forms/PersonalityForm";
import KnowledgeForm from "./components/forms/KnowledgeForm";
import DeployForm from "./components/forms/DeployForm";
import { observer } from "mobx-react-lite";
import SynkLogo from "components/SynkLogo/SynkLogo";
import { NextUIProvider } from "@nextui-org/react";

const Test = observer(() => {
  const tabs = [
    {
      title: "Identity",
      value: "identity",
      content: (title: string) => (
        <div className="w-full overflow-hidden relative min-h-[400px] rounded-2xl p-10 text-xl @md:text-4xl font-bold text-white bg-gradient-to-br from-customGray to-customGray shadow-custom">
          <p>{title}</p>
          <IdentityForm />
        </div>
      ),
    },
    {
      title: "Personality",
      value: "personality",
      content: (title: string) => (
        <div className="w-full overflow-hidden relative min-h-[400px] rounded-2xl p-10 text-xl @md:text-4xl font-bold text-white bg-gradient-to-br from-customGray to-customGray shadow-custom">
          <p>{title}</p>
          <PersonalityForm />
        </div>
      ),
    },
    {
      title: "Knowledge",
      value: "knowledge",
      content: (title: string) => (
        <div className="w-full overflow-hidden relative min-h-[400px] rounded-2xl p-10 text-xl @md:text-4xl font-bold text-white bg-gradient-to-br from-customGray to-customGray shadow-custom">
          <p>{title}</p>
          <KnowledgeForm />
        </div>
      ),
    },
    {
      title: "Deploy",
      value: "deploy",
      content: (title: string) => (
        <div className="w-full overflow-hidden relative min-h-[400px] rounded-2xl p-10 text-xl @md:text-4xl font-bold text-white bg-gradient-to-br from-customGray to-customGray shadow-custom">
          <p>{title}</p>
          <DeployForm />
        </div>
      ),
    },
  ];

  return (
    <div className="@container mytheme ">
      <div className="flex flex-col min-h-screen pt-10 @md:pt-20 relative overflow-hidden z-0">
        <header className="absolute top-3 w-full bg-transparent flex justify-center py-4 z-20">
          <div className="flex items-center space-x-2">
            <div
              className={`relative z-10 text-center text-synkColor font-logo text-[2.1rem] leading-[100%] tracking-[-0.08em] gradient-stroke-less`}
            >
              Synk
            </div>
          </div>
        </header>

        <div className="max-w-7xl mx-auto px-4 flex flex-col items-center justify-center">
          <h1 className="tracking-tight bg-clip-text text-transparent bg-gradient-to-b from-neutral-800 via-white to-white text-4xl @md:text-4xl @lg:text-8xl font-semibold max-w-6xl mx-auto text-center mt-6 relative z-10 py-6">
            Do things differently with Synk BOT
          </h1>
          <h1 className="my-4 font-normal text-center mt-2 @md:mt-6 text-base @md:text-xl text-muted-dark max-w-3xl mx-auto relative z-10">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Praesent
            rutrum feugiat ante ac laoreet. Fusce vitae condimentum dolor, quis
            vulputate tortor.
          </h1>
        </div>

        <div className="flex relative flex-col container mx-auto p-4 h-[550px]">
          <Tabs
            tabs={tabs.map((tab) => ({
              ...tab,
              content: tab.content(tab.title),
            }))}
            containerClassName="space-x-1"
            activeTabClassName=""
            tabClassName="text-gray-200"
            contentClassName="bg-transparent rounded-lg"
          />
        </div>

        <div className="flex items-center gap-4 justify-center my-10 relative z-10">
          <button className="group hover:-translate-y-0.5 active:scale-[0.98] bg-synkColor relative z-10 hover:bg-synkColor/90 border border-synkColor text-black text-sm @md:text-sm transition font-medium duration-200 rounded-md px-4 py-2 justify-center shadow-[0px_-1px_0px_0px_#FFFFFF60_inset,_0px_1px_0px_0px_#FFFFFF60_inset] flex space-x-2 items-center group !text-lg">
            <span>Custom your Bot</span>
            <svg
              stroke="currentColor"
              fill="currentColor"
              strokeWidth="0"
              viewBox="0 0 24 24"
              aria-hidden="true"
              className="text-black group-hover:translate-x-1 stroke-[1px] h-3 w-3 mt-0.5 transition-transform duration-200"
              height="1em"
              width="1em"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                d="M12.97 3.97a.75.75 0 0 1 1.06 0l7.5 7.5a.75.75 0 0 1 0 1.06l-7.5 7.5a.75.75 0 1 1-1.06-1.06l6.22-6.22H3a.75.75 0 0 1 0-1.5h16.19l-6.22-6.22a.75.75 0 0 1 0-1.06Z"
                clipRule="evenodd"
              ></path>
            </svg>
          </button>
        </div>

        <div className="h-[100px]"></div>

        <div className="relative">
          <p className="text-center text-5xl @md:text-9xl @lg:text-[18rem] font-bold bg-clip-text text-transparent bg-gradient-to-b from-synkColor/60 to-synkColor/20 inset-x-0">
            SYNK BOT
          </p>
        </div>
      </div>
    </div>
  );
});

export default Test;
