import { observer } from "mobx-react-lite";
import { Input, Select, SelectItem } from "@nextui-org/react";
import formStore from "../../stores/FormStore";
import { llmOptions } from "../../data/llmOptions";

const IdentityForm = observer(() => {
  return (
    <div className="flex flex-col gap-4 p-4">
      <Input
        label="Name"
        placeholder="Enter your name"
        value={formStore.name}
        onChange={(e) => formStore.setName(e.target.value)}
      />
      <Input
        label="Description"
        placeholder="Enter description"
        value={formStore.description}
        onChange={(e) => formStore.setDescription(e.target.value)}
      />
      <Select
        label="LLM Choice"
        placeholder="Select an LLM"
        value={formStore.llmChoice}
        onSelectionChange={(value) => formStore.setLLMChoice(value as string)}
        className="w-full"
      >
        {llmOptions.map((option) => (
          <SelectItem key={option.key} value={option.key}>
            {option.label}
          </SelectItem>
        ))}
      </Select>
    </div>
  );
});

export default IdentityForm;
