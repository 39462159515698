import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import appStore from "stores/AppStore";
import { Dock, DockCard, DockCardInner } from "components/UI/floating-dock2";

const DockComponent: React.FC = observer(() => {
  useEffect(() => {
    appStore.fetchApps();
  }, []);

  const dockItems = appStore.availableApps.map((app, index) => {
    const iconSrc = app.appLogoUrl;

    const icon = (
      <img
        src={iconSrc}
        alt={app.appName}
        className="h-8 w-8 fill-black stroke-black rounded-full"
      />
    );

    return {
      id: index.toString(),
      title: app.appName,
      icon: icon,
      src: iconSrc,
    };
  });

  const handleAppClick = (appName: string) => {
    appStore.focusOrLaunchApp(appName);
  };

  return (
    <Dock>
      {dockItems.map((item) => {
        const runningInstances = Array.from(
          appStore.runningApps.values()
        ).filter((instance) => instance.appName === item.title);

        const isRunning = runningInstances.length > 0;

        return (
          <DockCard
            key={item.id}
            id={item.id}
            onClick={() => handleAppClick(item.title)}
            isRunning={isRunning}
            runningInstances={runningInstances}
            appName={item.title}
          >
            <DockCardInner src={item.src} id={item.id}>
              {item.icon}
            </DockCardInner>
          </DockCard>
        );
      })}
    </Dock>
  );
});

export default DockComponent;
