import React from "react";
import { Auth0Provider } from "@auth0/auth0-react";

interface Auth0ProviderProps {
  children: React.ReactNode;
}

const Auth0ProviderWithHistory: React.FC<Auth0ProviderProps> = ({
  children,
}) => {
  const domain = process.env.REACT_APP_AUTH0_DOMAIN!;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID!;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: audience,
        scope: "openid profile email read:apps",
      }}
      cacheLocation="memory"
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;
