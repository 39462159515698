import React from "react";
import { Button } from "@nextui-org/react";
import Sidebar from "./Sidebar";

const DrawerContent: React.FC = () => {
  return (
    <div className="p-4 h-full relative">
      <Sidebar />
    </div>
  );
};

export default DrawerContent;
