import React from "react";
import userStore from "stores/UserStore";
import { useTranslation } from "react-i18next";

interface AuthIframeProps
  extends React.IframeHTMLAttributes<HTMLIFrameElement> {
  src: string;
  title: string;
}

const AuthIframe: React.FC<AuthIframeProps> = ({ src, title, ...props }) => {
  const { t } = useTranslation();
  const accessToken = userStore.accessToken;

  const appendTokenToUrl = (url: string, token: string | null): string => {
    if (!token) return url;

    try {
      const urlObj = new URL(url, window.location.origin);
      urlObj.searchParams.set("pending_token", token);
      return urlObj.toString();
    } catch (e) {
      console.error(t("invalid_url"), url);
      return url;
    }
  };

  if (!accessToken) {
    return (
      <div className="w-full h-full flex items-center justify-center bg-gray-100">
        <p>{t("missing_auth_token")}</p>
      </div>
    );
  }

  const finalSrc = appendTokenToUrl(src, accessToken);

  return <iframe src={finalSrc} title={title} {...props} />;
};

export default AuthIframe;
