import axios from "axios";
import userStore from "stores/UserStore";

const isLocalMode = process.env.REACT_APP_DEV_MODE === "true";

const apiClient = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  withCredentials: isLocalMode,
});

apiClient.interceptors.request.use(
  (config) => {
    if (isLocalMode) {
      const token = userStore.accessToken;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    } else {
      const token = userStore.accessToken;
      if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
      }
    }
    return config;
  },
  (error) => Promise.reject(error)
);

apiClient.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 401) {
      userStore.handleTokenExpiration();
    }
    return Promise.reject(error);
  }
);

export default apiClient;
