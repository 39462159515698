import type { ThemeConfig } from "antd/es/config-provider/context";

export const theme: ThemeConfig = {
  token: {
    borderRadius: 14,
    colorBgBlur: "10",
    colorBgElevated: "bg-customGray/60",
    colorText: "text-white",
    colorTextHeading: "text-white",
    fontFamily: "Helvetica Neue",
    fontSize: 14,
    boxShadow: "shadow-custom2",
    colorIcon: "#22dcd1",
    colorIconHover: "#ADE9DE",
    colorPrimary: "rgba(34, 220, 209, 0.2)",
  },
  components: {
    Notification: {},
  },
};
