"use client";
import React, { useState, useRef, createContext } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, A11y } from "swiper/modules";
import { Swiper as SwiperType } from "swiper";
import { IconArrowNarrowLeft, IconArrowNarrowRight } from "@tabler/icons-react";
import { motion } from "framer-motion";
import { Image } from "@nextui-org/react";
import { PulsatingButton } from "./PulsatingBtn";

import { CardContainer, CardBody, CardItem } from "./3d-card";

import "swiper/css";
import "swiper/css/navigation";

interface CarouselProps {
  items: JSX.Element[];
  initialScroll?: number;
}

type Card = {
  src: string;
  title: string;
  url: string;
  category: string;
  content?: React.ReactNode;
};

interface CardProps {
  src: string;
  title: string;
  category: string;
  content?: React.ReactNode;
  cardAction?: {
    onClick?: (event: React.MouseEvent<HTMLDivElement>) => void;
    url?: string;
    openInNewTab?: boolean;
  };
  buttonProps: {
    type: "pulsating" | "regular";
    text: string;
    onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
    url?: string;
    openInNewTab?: boolean;
    isLoading?: boolean;
  };
}

export const CarouselContext = createContext<{
  onCardClose: (index: number) => void;
  currentIndex: number;
}>({
  onCardClose: () => {},
  currentIndex: 0,
});

export const Carousel = ({ items }: CarouselProps) => {
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);
  const swiperRef = useRef<SwiperType | null>(null);

  const [canScrollLeft, setCanScrollLeft] = useState(false);
  const [canScrollRight, setCanScrollRight] = useState(true);

  const scrollLeftFunc = () => {
    if (swiperRef.current) {
      swiperRef.current.slidePrev();
    }
  };

  const scrollRightFunc = () => {
    if (swiperRef.current) {
      swiperRef.current.slideNext();
    }
  };

  const updateNavigationState = () => {
    if (swiperRef.current) {
      setCanScrollLeft(!swiperRef.current.isBeginning);
      setCanScrollRight(!swiperRef.current.isEnd);
    }
  };

  return (
    <div className="relative w-full overflow-hidden">
      <Swiper
        modules={[Navigation, A11y]}
        spaceBetween={16}
        slidesPerView={"auto"}
        onSwiper={(swiper: SwiperType) => {
          swiperRef.current = swiper;

          if (
            swiper.params.navigation &&
            typeof swiper.params.navigation !== "boolean"
          ) {
            swiper.params.navigation.prevEl = prevRef.current;
            swiper.params.navigation.nextEl = nextRef.current;
            swiper.navigation.init();
            swiper.navigation.update();
          }

          updateNavigationState();
        }}
        onSlideChange={updateNavigationState}
        onReachEnd={updateNavigationState}
        onReachBeginning={updateNavigationState}
        className="flex flex-row justify-start gap-4 max-w-7xl mx-auto p-5 overflow-visible"
        slidesOffsetAfter={0}
      >
        {items.map((item, index) => (
          <SwiperSlide
            key={"card" + index}
            className="last:pr-[5%] md:last:pr-[33%] rounded-3xl w-[80%] sm:w-auto"
            style={{ width: "auto" }}
          >
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{
                opacity: 1,
                y: 0,
                transition: {
                  duration: 0.5,
                  delay: 0.2 * index,
                  ease: "easeOut",
                },
              }}
            >
              {item}
            </motion.div>
          </SwiperSlide>
        ))}
      </Swiper>

      <div className="flex mt-5 justify-end mr-10 h-[45px]">
        <div className="hidden md:flex gap-2">
          <button
            ref={prevRef}
            className="relative z-40 h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center disabled:opacity-50"
            onClick={scrollLeftFunc}
            disabled={!canScrollLeft}
          >
            <IconArrowNarrowLeft className="h-6 w-6 text-gray-500" />
          </button>
          <button
            ref={nextRef}
            className="relative z-40 h-10 w-10 rounded-full bg-gray-100 flex items-center justify-center disabled:opacity-50"
            onClick={scrollRightFunc}
            disabled={!canScrollRight}
          >
            <IconArrowNarrowRight className="h-6 w-6 text-gray-500" />
          </button>
        </div>
      </div>
    </div>
  );
};

export const Card = ({ card, index }: { card: CardProps; index: number }) => {
  const handleCardClick = (event: React.MouseEvent<HTMLDivElement>) => {
    if (card.cardAction?.onClick) {
      card.cardAction.onClick(event);
    } else if (card.cardAction?.url) {
      const target = card.cardAction.openInNewTab ? "_blank" : "_self";
      window.open(card.cardAction.url, target, "noopener,noreferrer");
    }
  };

  return (
    <div className="w-full sm:w-auto">
      <CardContainer className="inter-var">
        <CardBody
          className="bg-gray-50 relative group/card dark:hover:shadow-3xl dark:hover:shadow-synkColor/[0.9] dark:bg-customGray/50 w-[300px] shadow-custom h-auto md:w-[25rem] md:h-[500px] rounded-xl p-6"
          onClick={handleCardClick}
          style={{ cursor: "pointer" }}
        >
          <CardItem
            translateZ="50"
            className="text-xl font-bold text-neutral-600 dark:text-white"
          >
            {card.title}
          </CardItem>
          <CardItem
            as="p"
            translateZ="60"
            className="text-neutral-500 text-sm max-w-sm mt-2 dark:text-neutral-300"
          >
            {card.category}
          </CardItem>
          <CardItem translateZ="100" className="w-full mt-10">
            <Image
              src={card.src}
              height="1000"
              width="1000"
              className="h-44 md:h-60 w-full object-cover rounded-xl group-hover/card:shadow-xl"
              alt={card.title}
            />
          </CardItem>
          <div className="flex justify-between items-center mt-20">
            <CardItem
              translateZ={20}
              className="hidden md:block px-4 py-2 rounded-xl text-xs font-normal dark:text-white"
            >
              Try now →
            </CardItem>
            <CardItem translateZ={20} as="div">
              {card.buttonProps.type === "pulsating" ? (
                card.buttonProps.url ? (
                  <PulsatingButton
                    as="a"
                    href={card.buttonProps.url}
                    target={card.buttonProps.openInNewTab ? "_blank" : "_self"}
                    rel="noopener noreferrer"
                    pulseColor="#22dcd1"
                    isLoading={card.buttonProps.isLoading}
                    className="px-4 py-2 rounded-xl text-xs font-bold inline-block text-center"
                    onClick={(
                      event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                    ) => event.stopPropagation()}
                  >
                    {card.buttonProps.text}
                  </PulsatingButton>
                ) : (
                  <PulsatingButton
                    onClick={(
                      event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                    ) => {
                      event.stopPropagation();
                      card.buttonProps.onClick?.(event);
                    }}
                    pulseColor="#22dcd1"
                    isLoading={card.buttonProps.isLoading}
                    className="px-4 py-2 rounded-xl text-xs font-bold"
                  >
                    {card.buttonProps.text}
                  </PulsatingButton>
                )
              ) : card.buttonProps.url ? (
                <a
                  href={card.buttonProps.url}
                  target={card.buttonProps.openInNewTab ? "_blank" : "_self"}
                  rel="noopener noreferrer"
                  className="px-4 py-2 rounded-xl bg-black dark:bg-white dark:text-black text-white text-xs font-bold inline-block text-center"
                  onClick={(
                    event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
                  ) => event.stopPropagation()}
                >
                  {card.buttonProps.text}
                </a>
              ) : (
                <button
                  onClick={(
                    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
                  ) => {
                    event.stopPropagation();
                    card.buttonProps.onClick?.(event);
                  }}
                  className="px-4 py-2 rounded-xl bg-black dark:bg-white dark:text-black text-white text-xs font-bold"
                >
                  {card.buttonProps.text}
                </button>
              )}
            </CardItem>
          </div>
        </CardBody>
      </CardContainer>
    </div>
  );
};
