"use client";

import React from "react";
import { useTranslation } from "react-i18next";
import userStore from "stores/UserStore";
import { BACKGROUNDS, isImage, isVideo } from "data/background";
import { Card, CardBody, CardFooter, Image } from "@nextui-org/react";
import { observer } from "mobx-react-lite";

const AppearanceTab = observer(() => {
  const { t } = useTranslation();

  const changeBackground = (url: string) => {
    userStore.setBackground(url);
  };

  return (
    <div className="grid grid-cols-1 @md:grid-cols-2 @lg:grid-cols-2 py-10 gap-4">
      {BACKGROUNDS.map((bg) => (
        <Card
          key={bg.id}
          shadow="sm"
          isPressable
          onPress={() => changeBackground(bg.url)}
          className={`${
            userStore.backgroundUrl === bg.url ? "border-success border-2" : ""
          }`}
        >
          <CardBody className="overflow-visible p-0">
            {isImage(bg.url) && (
              <Image
                shadow="sm"
                radius="lg"
                width="100%"
                alt={bg.name}
                className="w-full object-cover h-[125px]"
                src={bg.url}
              />
            )}
            {isVideo(bg.url) && (
              <video
                className="w-full object-cover h-[125px]"
                autoPlay
                loop
                muted
              >
                <source src={bg.url} type="video/mp4" />
              </video>
            )}
          </CardBody>
          <CardFooter className="text-small justify-between">
            <b>{bg.name}</b>
          </CardFooter>
        </Card>
      ))}
    </div>
  );
});

export default AppearanceTab;
