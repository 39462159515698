import { makeAutoObservable } from "mobx";

class SelectionStore {
  isSelecting: boolean = false;
  selectionStart: { x: number; y: number } | null = null;
  selectionEnd: { x: number; y: number } | null = null;

  constructor() {
    makeAutoObservable(this);
  }

  startSelection(x: number, y: number) {
    this.isSelecting = true;
    this.selectionStart = { x, y };
    this.selectionEnd = { x, y };
  }

  updateSelection(x: number, y: number) {
    if (this.isSelecting) {
      this.selectionEnd = { x, y };
    }
  }

  endSelection() {
    this.isSelecting = false;
    this.selectionStart = null;
    this.selectionEnd = null;
  }
}

const selectionStore = new SelectionStore();
export default selectionStore;
