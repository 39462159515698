import React, { useEffect } from "react";
import { observer } from "mobx-react-lite";
import isolatedAppStore from "pages/IsolatedApp/store/isolatedAppStore";
import { Ripple } from "components/UI/Ripple";
import { AnimatedCircularProgressBar } from "components/UI/circularProgressBar";
import { ShootingStars } from "components/UI/shooting-stars";
import { StarsBackground } from "components/UI/stars-background";
import { RetroGrid } from "components/UI/RetroGrid";
import { Avatar } from "@nextui-org/react";
import { NeonGradientCard } from "components/UI/NeonGradient";
import { FlipWords } from "components/UI/flip-words";
import { BackgroundGradient } from "components/UI/background-gradient";

const LoadingPage: React.FC = observer(() => {
  const words = ["secure", "private", "protected", "anonymous", "trusted"];

  return (
    <div className="flex items-center justify-center bg-black antialiased py-20 h-[100svh] w-full relative">
      <div className="absolute inset-0 bg-customGray/40 z-0"></div>

      <div
        className="absolute inset-0 bg-cover bg-no-repeat"
        style={{
          backgroundImage: 'url("/images/Ellipse1.png")',
          zIndex: 1,
        }}
      ></div>

      <div className="flex flex-col items-center relative z-10 overflow-hidden">
        <div className="mb-[20px] lg:mb-[50px]">
          <div className="text-base sm:text-lg md:text-xl lg:text-2xl mx-auto font-normal text-neutral-400 overflow-hidden">
            Patience, you will soon be
            <FlipWords words={words} /> <br />
            empowered by the strength of{" "}
            <span className="!text-white font-logo text-[1rem] sm:text-[1.2rem] md:text-[1.4rem] lg:text-[1.6rem] xl:text-[1.8rem] leading-[100%] tracking-[-0.08em] gradient-stroke">
              Synk
            </span>
          </div>
        </div>

        <div className="w-1/2 h-1/2 min-w-[250px] min-h-[380px] lg:min-h-[500px] flex items-center justify-center z-40 rounded-lg"></div>

        <div className="mt-[20px] lg:mb-[50px]">
          <div className="relative text-xl font-semibold text-white">
            {Math.floor(isolatedAppStore.loadingProgress)}%
          </div>
        </div>
      </div>

      <Ripple numCircles={3} className="z-30 block md:hidden" />
      <Ripple numCircles={3} className="z-30 hidden md:block lg:hidden" />
      <Ripple numCircles={5} className="z-30 hidden lg:block" />

      <AnimatedCircularProgressBar
        max={100}
        min={0}
        value={isolatedAppStore.loadingProgress}
        gaugePrimaryColor="#22dcd1"
        gaugeSecondaryColor="rgba(0, 0, 0, 0.1)"
        size={150}
        className="absolute"
      />

      <div className="absolute flex w-full flex-col items-center justify-center">
        <span className="pointer-events-none whitespace-pre-wrap bg-gradient-to-b from-black to-gray-300 bg-clip-text text-center text-8xl font-semibold leading-none text-transparent dark:from-white dark:to-black">
          <BackgroundGradient containerClassName="max-w-sm flex items-center justify-center text-center w-33 h-33 block lg:hidden">
            <div className="rounded-full bg-customGray/80 w-[124px] h-[124px] flex max-w-sm items-center justify-center text-center">
              <Avatar
                classNames={{
                  base: "bg-transparent animate-custom-bounce",
                }}
                src="/images/app/firefox.svg"
                className="w-20 h-20"
              />
            </div>
          </BackgroundGradient>

          <NeonGradientCard
            borderRadius={999}
            className="max-w-sm items-center justify-center text-center hidden lg:block"
            neonColors={{ firstColor: "#22dcd1", secondColor: "#14467D" }}
          >
            <Avatar
              classNames={{
                base: "bg-transparent animate-custom-bounce",
              }}
              src="/images/app/firefox.svg"
              className="w-20 h-20"
            />
          </NeonGradientCard>
        </span>
      </div>

      <ShootingStars />
      <StarsBackground className="max-h-[50svh]" />
      <RetroGrid />
    </div>
  );
});

export default LoadingPage;
