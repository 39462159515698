import React from "react";
import { observer } from "mobx-react-lite";
import selectionStore from "stores/SelectionStore";

const SelectionBox: React.FC = observer(() => {
  const { isSelecting, selectionStart, selectionEnd } = selectionStore;

  if (!isSelecting || !selectionStart || !selectionEnd) return null;

  const x = Math.min(selectionStart.x, selectionEnd.x);
  const y = Math.min(selectionStart.y, selectionEnd.y);
  const width = Math.abs(selectionEnd.x - selectionStart.x);
  const height = Math.abs(selectionEnd.y - selectionStart.y);

  return (
    <div
      className="absolute bg-synkColor/20 border-small border-customGray/20 rounded"
      style={{
        left: x,
        top: y,
        width: width,
        height: height,
        pointerEvents: "none",
      }}
    />
  );
});

export default SelectionBox;
