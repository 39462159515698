import React, { useRef, useEffect, useCallback, useState } from "react";
import RFB from "@novnc/novnc";
import { observer } from "mobx-react-lite";
import { runInAction } from "mobx";
import isolatedAppStore from "pages/IsolatedApp/store/isolatedAppStore";
import { Skeleton } from "@nextui-org/react";

interface NoVNCViewerProps {
  url: string;
}

const NoVNCViewer: React.FC<NoVNCViewerProps> = observer(({ url }) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const rfbRef = useRef<RFB | null>(null);
  const isAttemptingConnectionRef = useRef(false);
  const attemptIntervalRef = useRef<NodeJS.Timeout | null>(null);
  const hiddenInputRef = useRef<HTMLInputElement | null>(null);
  const attemptStartTimeRef = useRef<number | null>(null);
  const loadingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isReconnecting, setIsReconnecting] = useState(false);

  const connect = useCallback(() => {
    if (containerRef.current && !isAttemptingConnectionRef.current) {
      console.log("Tentative de connexion VNC...");
      isAttemptingConnectionRef.current = true;

      if (rfbRef.current) {
        console.log("Déconnexion de l'instance RFB");
        rfbRef.current.disconnect();
        rfbRef.current = null;
      }

      try {
        const rfb = new RFB(containerRef.current, url);

        rfb.scaleViewport = true;
        rfb.resizeSession = true;
        rfb.clipViewport = false;
        rfb.dragViewport = true;
        rfb.keyboardEnabled = true;

        const handleConnect = () => {
          console.log("Connecté à VNC");

          runInAction(() => {
            isolatedAppStore.setConnected(true);
          });

          if (loadingTimeoutRef.current) {
            clearTimeout(loadingTimeoutRef.current);
            loadingTimeoutRef.current = null;
          }

          runInAction(() => {
            isolatedAppStore.setLoading(false);
            setIsReconnecting(false);
          });

          isolatedAppStore.stopLoadingProgress();

          if (attemptIntervalRef.current) {
            clearInterval(attemptIntervalRef.current);
          }

          const start = Math.floor(isolatedAppStore.loadingProgress);
          const end = 100;
          const duration = isolatedAppStore.delayAfterConnection;
          const startTime = Date.now();

          const animateProgress = () => {
            const elapsed = Date.now() - startTime;
            if (elapsed < duration) {
              const progress = start + ((end - start) * elapsed) / duration;
              runInAction(() => {
                isolatedAppStore.setLoadingProgress(progress);
              });
              requestAnimationFrame(animateProgress);
            } else {
              runInAction(() => {
                isolatedAppStore.setLoadingProgress(100);
                isolatedAppStore.setLoading(false);
              });
            }
          };

          animateProgress();
        };

        const handleDisconnect = () => {
          console.log("Déconnecté de VNC");

          runInAction(() => {
            isolatedAppStore.setConnected(false);
            setIsReconnecting(true);
          });

          isAttemptingConnectionRef.current = false;

          if (
            isolatedAppStore.remainingTime !== null &&
            isolatedAppStore.remainingTime > 0
          ) {
            const loadingTimeout = setTimeout(() => {
              runInAction(() => {
                isolatedAppStore.setLoading(true);
              });
            }, 3000);

            loadingTimeoutRef.current = loadingTimeout;
          } else {
            runInAction(() => {
              isolatedAppStore.setLoading(false);
            });
          }
        };

        rfb.addEventListener("connect", handleConnect);
        rfb.addEventListener("disconnect", handleDisconnect);
        rfb.addEventListener("securityfailure", (e: any) => {
          console.error("Erreur de sécurité", e.detail.status);
          isAttemptingConnectionRef.current = false;
        });
        rfb.addEventListener("error", (e: any) => {
          console.error("Erreur RFB :", e);
          isAttemptingConnectionRef.current = false;
        });

        rfbRef.current = rfb;

        isolatedAppStore.sendClipboardText = (text: string) => {
          rfb.clipboardPasteFrom(text);
        };
      } catch (error) {
        console.error("Erreur lors de la création de l'instance RFB :", error);
        isAttemptingConnectionRef.current = false;
      }
    } else {
      console.error(
        "containerRef.current est nul ou une autre connexion est déjà ouverte"
      );
    }
  }, [url]);

  const startConnectionAttempts = useCallback(() => {
    attemptStartTimeRef.current = Date.now();
    connect();
    attemptIntervalRef.current = setInterval(() => {
      if (!isolatedAppStore.connected && !isAttemptingConnectionRef.current) {
        const elapsed =
          Date.now() - (attemptStartTimeRef.current || Date.now());
        if (elapsed >= isolatedAppStore.MAX_ATTEMPT_DURATION) {
          if (attemptIntervalRef.current) {
            clearInterval(attemptIntervalRef.current);
          }
          runInAction(() => {
            isolatedAppStore.remainingTime = 0;
            isolatedAppStore.isLoading = false;
          });
        } else {
          connect();

          runInAction(() => {
            if (isolatedAppStore.loadingProgress < 90) {
              isolatedAppStore.incrementProgress(2);
            }
          });
        }
      }
    }, 1000);
  }, [connect]);

  useEffect(() => {
    startConnectionAttempts();

    return () => {
      if (rfbRef.current) {
        rfbRef.current.disconnect();
        rfbRef.current = null;
      }
      if (attemptIntervalRef.current) {
        clearInterval(attemptIntervalRef.current);
      }
      if (loadingTimeoutRef.current) {
        clearTimeout(loadingTimeoutRef.current);
        loadingTimeoutRef.current = null;
      }
    };
  }, [url, startConnectionAttempts]);

  useEffect(() => {
    if (
      isolatedAppStore.remainingTime !== null &&
      isolatedAppStore.remainingTime <= 0 &&
      rfbRef.current
    ) {
      rfbRef.current.disconnect();
      rfbRef.current = null;
    }
  }, [isolatedAppStore.remainingTime]);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        if (!isolatedAppStore.connected && !isAttemptingConnectionRef.current) {
          startConnectionAttempts();
        }
      } else {
        if (rfbRef.current) {
          rfbRef.current.disconnect();
          rfbRef.current = null;
        }
      }
    };

    document.addEventListener("visibilitychange", handleVisibilityChange);

    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, [startConnectionAttempts]);

  const handleCanvasTouchStart = () => {
    setTimeout(() => {
      if (hiddenInputRef.current) {
        hiddenInputRef.current.focus();
      }
    }, 0);
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (rfbRef.current && value) {
      for (let i = 0; i < value.length; i++) {
        const char = e.target.value.charAt(i);
        rfbRef.current.sendKey(char.charCodeAt(0), char, true);
        rfbRef.current.sendKey(char.charCodeAt(0), char, false);
      }
    }
    e.target.value = "";
  };

  const handleInputBlur = () => {};

  return (
    <div
      ref={containerRef}
      className="relative w-full h-full overflow-hidden rounded-[15px] border border-primary"
      onTouchStart={handleCanvasTouchStart}
    >
      <input
        ref={hiddenInputRef}
        type="text"
        style={{
          position: "absolute",
          opacity: 0.01,
          height: 1,
          width: 1,
          border: "none",
          outline: "none",
          zIndex: -1,
        }}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        autoComplete="off"
        autoCorrect="off"
        autoCapitalize="off"
        spellCheck="false"
      />

      <button
        onClick={() => {
          if (hiddenInputRef.current) {
            hiddenInputRef.current.focus();
          }
        }}
        style={{
          position: "absolute",
          bottom: 10,
          right: 10,
          zIndex: 10,
          padding: "10px",
          backgroundColor: "rgba(255, 255, 255, 0.5)",
          border: "none",
          borderRadius: "5px",
        }}
      >
        KeyBoard
      </button>
      {isReconnecting && (
        <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 z-20">
          <Skeleton className="w-full h-full" />
        </div>
      )}
    </div>
  );
});

export default NoVNCViewer;
