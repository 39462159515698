import React, { useState, useEffect } from "react";
import { Button } from "components/UI/Button";
import { Clock } from "lucide-react";
import {
  Dropdown,
  DropdownTrigger,
  DropdownMenu,
  DropdownItem,
} from "@nextui-org/react";
import { Calendar } from "@nextui-org/react";
import { today, getLocalTimeZone, CalendarDate } from "@internationalized/date";
import { I18nProvider } from "@react-aria/i18n";
import userStore from "stores/UserStore";
import { observer } from "mobx-react-lite";
import { useTranslation } from "react-i18next";

const BtnDate: React.FC = observer(() => {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState("");
  const [selectedDate, setSelectedDate] = useState<CalendarDate>(
    today(getLocalTimeZone())
  );

  useEffect(() => {
    const updateDate = () => {
      const now = new Date();
      const locale = userStore.currentLanguage;

      const options: Intl.DateTimeFormatOptions = {
        weekday: "short",
        day: "2-digit",
        month: "short",
        hour: "2-digit",
        minute: "2-digit",
      };

      const formattedDate = now
        .toLocaleString(locale, options)
        .replace(",", "");
      setCurrentDate(formattedDate);
    };

    updateDate();
    const intervalId = setInterval(updateDate, 60000);
    return () => clearInterval(intervalId);
  }, [userStore.currentLanguage]);

  const handleDateChange = (newDate: CalendarDate) => {
    setSelectedDate(newDate);
  };

  const toggleShowDate = () => {
    userStore.toggleShowDate();
  };

  return (
    <I18nProvider locale={userStore.currentLanguage}>
      <div className="flex items-center">
        {userStore.showDate ? (
          <Dropdown
            closeOnSelect={false}
            disableAnimation
            classNames={{
              base: "before:shadow-custom",
              content:
                "overflow-auto max-h-[80vh] p-0 bg-customGray/60 backdrop-blur-dock border-small border-divider shadow-custom relative",
            }}
            placement="bottom-start"
          >
            <DropdownTrigger>
              <Button
                variant="topbar"
                size="topbar"
                aria-label={t("open_calendar")}
              >
                <Clock className="mr-2 h-4 w-4" />
                {currentDate}
              </Button>
            </DropdownTrigger>
            <DropdownMenu
              aria-label={t("open_calendar")}
              variant="flat"
              itemClasses={{
                base: ["data-[hover=true]:bg-custom2"],
              }}
            >
              <DropdownItem key="calendar" textValue="Date Picker">
                <Calendar
                  aria-label="Date Picker"
                  showMonthAndYearPickers
                  value={selectedDate}
                  color="success"
                  onChange={handleDateChange}
                  showShadow={false}
                  className="border-none shadow-none bg-transparent"
                  classNames={{
                    headerWrapper: ["bg-transparent"],
                    gridHeader: ["bg-transparent"],
                    header: ["bg-lightColor/15"],
                  }}
                />
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>
        ) : (
          <Button
            variant="topbar"
            size="topbar"
            onClick={toggleShowDate}
            aria-label={t("show_date")}
          >
            <Clock className="h-4 w-4" />
          </Button>
        )}
      </div>
    </I18nProvider>
  );
});

export default BtnDate;
