import ReactDOM from "react-dom/client";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import SynkOSv2 from "pages/SynkOSv2/SynkOSv2";
import Test2 from "pages/Test2/Test2";
import IsolatedApp from "pages/IsolatedApp/IsolatedApp";
import { HomePage } from "pages/HomePage/HomePage";
import "./assets/styles/index.css";
import "./i18n";
import Auth0ProviderWithHistory from "auth/Auth0ProviderWithHistory";
import { NextUIProvider } from "@nextui-org/react";
import { theme } from "theme/theme";
import { ROUTES } from "routes/routes";
import { ConfigProvider } from "antd";
import { NotificationProvider } from "components/UI/NotificationProvider";
import LoadingPage from "pages/IsolatedApp/components/LoadingPage";
import NotFoundPage from "pages/NotFoundPage/NotFoundPage";
import SynkSearchEngine from "pages/SynkSearchEngine/SynkSearchEngine";
import { Test3 } from "pages/Test3/Test3";
import { Test4 } from "pages/Test4/Test4";
import RateLimit from "pages/RateLimit/RateLimit";
import InstanceStarter from "pages/InstanceStarter/InstanceStarter";
import ErrorPage from "pages/ErrorPage/ErrorPage";
import { SynkSessionStarter } from "pages/Test5/Test5";
import { Test6 } from "pages/Test6/Test6";
import { DNSOverridePage } from "pages/DNSOverridePage/DNSOverridePage";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <ConfigProvider theme={theme}>
    <NotificationProvider>
      <NextUIProvider>
        <main className="dark text-foreground bg-background">
          <Auth0ProviderWithHistory>
            <Router>
              <Routes>
                <Route path={ROUTES.HOME} element={<HomePage />} />
                <Route path={ROUTES.ISOLATEDAPP} element={<IsolatedApp />} />
                <Route path={ROUTES.START_FX} element={<InstanceStarter />} />
                <Route path={ROUTES.RATE_LIMIT} element={<RateLimit />} />
                <Route path={ROUTES.ERROR_PAGE} element={<ErrorPage />} />
                <Route path={ROUTES.OS} element={<SynkOSv2 />} />
                <Route
                  path={ROUTES.DNS_OVERRIDE}
                  element={<DNSOverridePage />}
                />

                <Route
                  path={ROUTES.SEARCHENGINE}
                  element={<SynkSearchEngine />}
                />
                {/* <Route path={ROUTES.TEST} element={<LoadingPage />} />
                <Route path={ROUTES.TEST2} element={<Test2 />} />
                <Route path={ROUTES.TEST3} element={<Test3 />} />
                <Route path={ROUTES.TEST4} element={<Test4 />} />
                <Route path={ROUTES.TEST5} element={<SynkSessionStarter />} />
                <Route path={ROUTES.TEST6} element={<Test6 />} /> */}
                <Route path="*" element={<NotFoundPage />} />
              </Routes>
            </Router>
          </Auth0ProviderWithHistory>
        </main>
      </NextUIProvider>
    </NotificationProvider>
  </ConfigProvider>
);
