import React from "react";
import { Input, Tooltip, Button as NextUIButton } from "@nextui-org/react";
import { RefreshCcw } from "lucide-react";
import { useTranslation } from "react-i18next";

interface IpAddressDisplayProps {
  ipAddress?: string | null;
  showRefreshButton?: boolean;
  onRefresh?: () => void;
  className?: string;
}

const IpAddressDisplay: React.FC<IpAddressDisplayProps> = ({
  ipAddress,
  showRefreshButton = false,
  onRefresh,
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className={`flex items-center justify-between group ${className}`}>
      <Input
        isReadOnly
        disabled
        label={t("ip_address")}
        value={ipAddress || t("unknown")}
        className="max-w-xs"
        aria-label={t("ip_address")}
        classNames={{
          input: [
            "filter blur-[5px] group-hover:blur-0 group-hover:bg-[#000000] transition duration-300",
            "group-data-[focus=true]:bg-transparent",
            "hover:bg-transparent",
          ],
          innerWrapper: "bg-transparent",
          inputWrapper: ["data-[hover=true]:bg-custom2"],
        }}
      />
    </div>
  );
};

export default IpAddressDisplay;
