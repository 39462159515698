import React from "react";
import { Cover } from "components/UI/cover";

interface SynkLogoProps {
  particleColor?: string;
  beamColor?: string;
  className?: string;
}

const SynkLogo: React.FC<SynkLogoProps> = ({
  particleColor = "#2EB9DF",
  beamColor = "#22dcd1",
  className = "",
}) => {
  return (
    <div
      className={`relative z-10 text-center text-white font-logo text-[2.1rem] leading-[100%] tracking-[-0.08em] gradient-stroke ${className}`}
    >
      <Cover particleColor={particleColor} beamColor={beamColor}>
        Synk
      </Cover>
    </div>
  );
};

export default SynkLogo;
