export const llmOptions = [
  { key: "ibm-watsonx", label: "IBM WatsonX" },
  { key: "ibm-misxtral", label: "IBM Mixstral 8" },
  { key: "ibm-llama", label: "IBM Llama 3.1" },
  { key: "gpt-3.5-turbo", label: "GPT-3.5 Turbo" },
  { key: "gpt-4o", label: "GPT-4o" },
  { key: "gpt-4-turbo", label: "GPT-4 Turbo" },
  { key: "gemini-1.5-pro", label: "Gemini 1.5 Pro" },
  { key: "gemini-1.5-flash", label: "Gemini 1.5 Flash" },
  { key: "gemini-1.0-pro", label: "Gemini 1.0 Pro" },
  { key: "claude-3-5-sonnet-20240620", label: "Claude 3.5 Sonnet" },
  { key: "claude-3-opus-20240229", label: "Claude 3 Opus" },
  { key: "claude-3-sonnet-20240229", label: "Claude 3 Sonnet" },
  { key: "claude-3-haiku-20240307", label: "Claude 3 Haiku" },
];
