import React, { useState } from "react";
import {
  Button,
  Divider,
  Card,
  CardBody,
  CardFooter,
  Checkbox,
  Input,
  ScrollShadow,
  Tooltip,
  Modal,
  Slider,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "@nextui-org/react";
import {
  Video,
  VideoOff,
  Mic,
  MicOff,
  Volume2,
  VolumeX,
  Maximize,
  Minimize,
  ClipboardCheck,
  Sun,
  Tv,
  Share2,
  Power,
  Copy,
} from "lucide-react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import isolatedAppStore from "../store/isolatedAppStore";
import { useNavigate } from "react-router-dom";
import { useIsDesktop } from "utils/useIsDesktop";
import { notificationManager } from "components/UI/notificationManager";

const Sidebar: React.FC = observer(() => {
  const [isSelected, setIsSelected] = React.useState(false);
  const [brightnessModalOpen, setBrightnessModalOpen] = useState(false);
  const [clipboardModalOpen, setClipboardModalOpen] = useState(false);
  const [clipboardText, setClipboardText] = useState("");
  const navigate = useNavigate();
  const isDesktop = useIsDesktop();

  const handleCopyLink = () => {
    navigator.clipboard.writeText(window.location.href);
    notificationManager.notify({
      message: "Link Copied",
      description: "The link has been copied to the clipboard.",
      type: "success",
    });
  };

  const buttons = [
    {
      icon: isolatedAppStore.isWebcamEnabled ? (
        <Video width={18} height={18} />
      ) : (
        <VideoOff width={18} height={18} />
      ),
      onClick: () => {
        isolatedAppStore.toggleWebcam();
      },
      tooltip: "Toggle Webcam",
    },
    {
      icon: isolatedAppStore.isVolumeMuted ? (
        <VolumeX width={18} height={18} />
      ) : (
        <Volume2 width={18} height={18} />
      ),
      onClick: () => {
        isolatedAppStore.toggleVolume();
      },
      tooltip: "Toggle Sound",
    },
    {
      icon: isolatedAppStore.isMicMuted ? (
        <MicOff width={18} height={18} />
      ) : (
        <Mic width={18} height={18} />
      ),
      onClick: () => {
        isolatedAppStore.toggleMic();
      },
      tooltip: "Toggle Microphone",
    },
    {
      icon: isolatedAppStore.isFullscreen ? (
        <Minimize width={18} height={18} />
      ) : (
        <Maximize width={18} height={18} />
      ),
      onClick: () => {
        if (isDesktop) {
          isolatedAppStore.toggleFullscreen();
        }
      },
      tooltip: isolatedAppStore.isFullscreen ? "Exit Fullscreen" : "Fullscreen",
      disabled: !isDesktop,
    },
  ];

  const handleBrightnessChange = (value: number | number[]) => {
    if (typeof value === "number") {
      isolatedAppStore.setBrightness(value);
    }
  };

  const buttons2 = [
    {
      icon: <ClipboardCheck width={18} height={18} />,
      label: "Clipboard",
      onClick: () => {
        setClipboardModalOpen(true);
      },
      tooltip: "Access Clipboard",
    },
    {
      icon: <Sun width={18} height={18} />,
      label: "Brightness",
      onClick: () => {
        setBrightnessModalOpen(true);
      },
      tooltip: "Adjust Brightness",
      disabled: true,
    },
    // {
    //   icon: <Tv width={18} height={18} />,
    //   label: "Streaming Quality",
    //   onClick: () => {},
    //   tooltip: "Change Streaming Quality [soon]",
    //   disabled: true,
    // },
    {
      icon: <Copy width={18} height={18} />,
      label: "Copy Link",
      onClick: handleCopyLink,
      tooltip: "Copy Link",
    },
  ];

  const buttons3 = [
    {
      icon: <Power width={18} height={18} />,
      label: "Log Out",
      onClick: () => {
        navigate("/");
      },
      tooltip: "Log Out Current Session",
    },
  ];

  const { t } = useTranslation();

  return (
    <div className="p-4 pt-0 pl-1.5 flex flex-col h-full max-h-full gap-5">
      <ScrollShadow className="-mr-4 h-full max-h-full py-6 pt-0 pr-2">
        <div className={`flex items-center justify-between group mb-4`}>
          <Input
            isReadOnly
            disabled
            label={t("ip_address")}
            value={isolatedAppStore.ipAddress}
            className="max-w-xs"
            aria-label={t("ip_address")}
            classNames={{
              input: [
                "filter blur-[5px] group-hover:blur-0 group-hover:bg-[#000000] transition duration-300",
                "group-data-[focus=true]:bg-transparent",
                "hover:bg-transparent",
              ],
              innerWrapper: "bg-transparent",
              inputWrapper: [
                "bg-transparent data-[hover=true]:bg-customGray/20",
              ],
            }}
          />
        </div>
        <div className="grid grid-cols-2 sm:grid-cols-2 gap-3">
          {buttons.map((button, index) => (
            <Tooltip
              key={index}
              content={button.tooltip}
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                onClick={button.onClick}
                radius="sm"
                variant="light"
                isIconOnly
                className="bg-customGray/20 h-12 w-full backdrop-blur-dock text-white"
                isDisabled={button.disabled}
              >
                {button.icon}
              </Button>
            </Tooltip>
          ))}
        </div>
        <Divider className="my-4" />
        <div className="flex flex-col w-full gap-2">
          {buttons2.map((button, index) => (
            <Tooltip
              key={index}
              content={button.tooltip}
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                variant="light"
                className="flex justify-start"
                startContent={button.icon}
                onClick={button.onClick}
                isDisabled={button.disabled}
              >
                {button.label}
              </Button>
            </Tooltip>
          ))}
        </div>
        <Divider className="my-4" />
        <div className="flex flex-col w-full gap-2">
          <Checkbox
            color="success"
            isSelected={isSelected}
            className="px-4 h-10 m-0"
            isDisabled
            classNames={{
              wrapper: "before:border-[#e5e7eb] before:w-[18px] h-[18px]",
              label: "font-normal text-small",
            }}
            onValueChange={setIsSelected}
          >
            AdBlock [soon]
          </Checkbox>

          <Checkbox
            color="success"
            isSelected={isSelected}
            onValueChange={setIsSelected}
            className="px-4 h-10 m-0"
            isDisabled
            classNames={{
              wrapper:
                "before:border-[#e5e7eb] before:w-[18px] before:h-[18px]",
              label: "font-normal text-small",
            }}
          >
            Tor [soon]
          </Checkbox>
        </div>
        <Divider className="my-4" />
        <div className="flex flex-col w-full gap-2">
          {buttons3.map((button, index) => (
            <Tooltip
              key={index}
              content={button.tooltip}
              delay={1000}
              classNames={{
                content: ["bg-customGray/80 backdrop-blur-custom"],
              }}
            >
              <Button
                variant="light"
                className="flex justify-start"
                startContent={button.icon}
                onClick={button.onClick}
              >
                {button.label}
              </Button>
            </Tooltip>
          ))}
        </div>
        <div className="mt-8">
          <Card className="mx-2 overflow-visible bg-customGray backdrop-blur-custom !shadow-custom">
            <CardBody className="items-center py-5 text-center">
              <h3 className="text-medium font-medium text-default-700">
                Follow Us on X
                <span aria-label="rocket-emoji" className="ml-2" role="img">
                  🚀
                </span>
              </h3>
              <p className="p-4 px-1 text-small text-default-600">
                Stay updated with our latest news and updates by following us on
                X.
              </p>
            </CardBody>
            <CardFooter className="absolute -bottom-8 justify-center">
              <Button
                className="px-10 shadow-md"
                radius="full"
                variant="shadow"
                as="a"
                href="https://x.com/synk_ws"
                target="_blank"
                rel="noopener noreferrer"
              >
                Follow Us
              </Button>
            </CardFooter>
          </Card>
        </div>
      </ScrollShadow>

      {/* Clipboard Modal */}
      <Modal
        closeButton
        backdrop="transparent"
        isOpen={clipboardModalOpen}
        onClose={() => setClipboardModalOpen(false)}
        classNames={{
          body: "py-6",
          base: "border-[#292f46] bg-customGray backdrop-blur-dock text-[#a8b0d3]",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <h3>Clipboard</h3>
              </ModalHeader>
              <ModalBody>
                <Input
                  label="Text"
                  type="textarea"
                  placeholder="Enter text to send to noVNC"
                  value={clipboardText}
                  onChange={(e) => setClipboardText(e.target.value)}
                />
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setClipboardModalOpen(false)}>
                  Close
                </Button>
                <Button
                  color="success"
                  onClick={() => {
                    isolatedAppStore.sendClipboardText(clipboardText);
                    setClipboardModalOpen(false);
                    setClipboardText("");
                  }}
                >
                  Send
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>

      {/* Brightness Modal */}
      <Modal
        closeButton
        backdrop="transparent"
        isOpen={brightnessModalOpen}
        onClose={() => setBrightnessModalOpen(false)}
        classNames={{
          body: "py-6",
          base: "border-[#292f46] bg-customGray backdrop-blur-dock text-[#a8b0d3]",
          header: "border-b-[1px] border-[#292f46]",
          footer: "border-t-[1px] border-[#292f46]",
          closeButton: "hover:bg-white/5 active:bg-white/10",
        }}
      >
        <ModalContent>
          {(onClose) => (
            <>
              <ModalHeader>
                <h3>Brightness Control</h3>
              </ModalHeader>
              <ModalBody>
                <div className="flex flex-col gap-4 w-full items-center justify-center">
                  <Slider
                    aria-label="Brightness"
                    size="lg"
                    color="success"
                    step={0.1}
                    minValue={0.5}
                    maxValue={1.5}
                    value={isolatedAppStore.brightness}
                    onChange={handleBrightnessChange}
                    startContent={<Sun className="text-2xl" size={20} />}
                    endContent={<Sun className="text-2xl" size={26} />}
                    className="max-w-md w-full"
                  />
                  <p className="text-default-500 font-medium text-small">
                    Current brightness: {isolatedAppStore.brightness.toFixed(1)}
                  </p>
                </div>
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setBrightnessModalOpen(false)}>
                  Close
                </Button>
              </ModalFooter>
            </>
          )}
        </ModalContent>
      </Modal>
    </div>
  );
});

export default Sidebar;
