import React from "react";
import { observer } from "mobx-react-lite";
import NoVNCViewer from "./VncViewer";
import isolatedAppStore from "../store/isolatedAppStore";
import { BorderBeam } from "components/UI/borderBeam";

const IframeComponent: React.FC = observer(() => {
  const { screenUrl, remainingTime } = isolatedAppStore;

  if (remainingTime !== null && remainingTime <= 0) {
    return <div>Sorry, your session has expired.</div>;
  }

  if (remainingTime === null) {
    return null;
  }
  return (
    <div className="flex-1 p-1.5 pl-0 pt-0 h-full min-h-0">
      <div className="relative w-full h-full">
        <NoVNCViewer url={screenUrl} />
        <BorderBeam duration={12} delay={9} className="rounded-[10px]" />
      </div>
    </div>
  );
});

export default IframeComponent;
