import React, { useEffect, useState } from "react";
import { Tabs, Tab } from "@nextui-org/react";
import { useTranslation } from "react-i18next";

import ProfileTab from "./components/ProfileTab/ProfileTab";
import VPNTab from "./components/VPNTab/VPNTab";
import AppearanceTab from "./components/AppearanceTab/AppearanceTab";
import { observer } from "mobx-react-lite";

interface SettingsProps {
  initialTab?: string;
}

const ProfilePage: React.FC<SettingsProps> = observer(({ initialTab }) => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState(initialTab || "profile");

  useEffect(() => {
    if (initialTab) {
      setSelectedTab(initialTab);
    }
  }, [initialTab]);

  const handleTabsChange = (key: React.Key) => {
    setSelectedTab(key as string);
  };

  return (
    <div className="w-full h-full flex justify-center p-4 @container">
      <div className="w-full max-w-3xl flex-1">
        <div className="flex items-center gap-x-3">
          <h1 className="text-3xl font-bold leading-9 text-default-foreground">
            {t("settings")}
          </h1>
        </div>
        <h2 className="mt-2 text-small text-default-500">
          {t("customize_settings_description")}
        </h2>

        <Tabs
          fullWidth
          selectedKey={selectedTab}
          onSelectionChange={handleTabsChange}
          classNames={{
            base: "mt-6",
            cursor: "bg-content1",
            panel: "w-full p-0 pt-4",
          }}
        >
          <Tab key="profile" title={t("profile")}>
            <ProfileTab />
          </Tab>
          <Tab key="vpn" title={t("vpn")}>
            <VPNTab />
          </Tab>

          <Tab key="appearance" title={t("appearance")}>
            <AppearanceTab />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
});

export default ProfilePage;
