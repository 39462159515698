export const ROUTES = {
  HOME: "/",
  OS: "/os",
  ISOLATEDAPP: "/app/firefox/:sessionId",
  DNS_OVERRIDE: "/safety/DNS_OVERRIDE",
  SEARCHENGINE: "/s",
  START_FX: "/startFirefox",
  RATE_LIMIT: "/rate-limit",
  ERROR_PAGE: "/whoopsie",
  NOT_FOUND: "*",

  TEST: "/test",
  TEST2: "/test2",
  TEST3: "/test3",
  TEST4: "/test4",
  TEST5: "/test5",
  TEST6: "/test6",
};
