import React, { useState, useEffect } from "react";
import { Tooltip, Chip } from "@nextui-org/react";
import { Check, X } from "lucide-react";
import { useTranslation } from "react-i18next";
import { observer } from "mobx-react-lite";
import userStore from "stores/UserStore";
import SynkLogo from "components/SynkLogo/SynkLogo";
import IpAddressDisplay from "components/IpAddressDisplay/IpAddressDisplay";

const BtnSynkLogo: React.FC = () => {
  const { t } = useTranslation();
  const [isOnline, setIsOnline] = useState<boolean | null>(null);

  useEffect(() => {
    const checkConnection = () => {
      fetch("https://www.google.com", { mode: "no-cors" })
        .then(() => setIsOnline(true))
        .catch(() => setIsOnline(false));
    };

    checkConnection();
    const intervalId = setInterval(checkConnection, 10000);

    return () => clearInterval(intervalId);
  }, []);

  const statusColor = isOnline ? "success" : "danger";
  const statusText = isOnline ? t("online") : t("offline");
  const statusIcon = isOnline ? <Check size={18} /> : <X size={18} />;

  const ipAddress = userStore.ipAddress;

  const particleColor = isOnline ? "#2EB9DF" : "#FF0000";
  const beamColor = isOnline ? "#22dcd1" : "#FF0000";

  const tooltipContent = (
    <div className="flex flex-col items-center justify-center space-y-2 p-3">
      <h3 className="font-semibold leading-none tracking-tight">
        {t("synk_status")}
      </h3>
      <div className="flex items-center justify-center gap-2 py-3">
        <Chip startContent={statusIcon} variant="faded" color={statusColor}>
          {statusText}
        </Chip>
        <p className="text-sm text-muted-foreground">
          {isOnline ? t("all_operational") : t("connection_lost")}
        </p>
      </div>
      <div className="flex items-center justify-center gap-1">
        <IpAddressDisplay ipAddress={ipAddress} className="mt-2" />
      </div>
    </div>
  );

  return (
    <Tooltip
      content={tooltipContent}
      closeDelay={300}
      classNames={{
        base: "before:shadow-custom",
        content:
          "overflow-auto max-h-[80vh] p-0 bg-customGray/60 backdrop-blur-dock border-small border-divider shadow-custom relative",
      }}
    >
      <div onMouseDown={(e) => e.stopPropagation()}>
        <SynkLogo particleColor={particleColor} beamColor={beamColor} />
      </div>
    </Tooltip>
  );
};

export default observer(BtnSynkLogo);
