import React from "react";
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Input,
  Progress,
  Button,
} from "@nextui-org/react";
import userStore from "stores/UserStore";
import { useTranslation } from "react-i18next";
import { useAuth0 } from "@auth0/auth0-react";
import { observer } from "mobx-react-lite";

interface AlertModalTokenExpireProps {
  isOpen: boolean;
  onClose: () => void;
}

const AlertModalTokenExpire = observer(function AlertModalTokenExpire({
  isOpen,
  onClose,
}: AlertModalTokenExpireProps) {
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const {
    formattedTokenRemainingTime,
    tokenProgress,
    tokenColor,
    tokenRemainingTime,
  } = userStore;

  const handleLogout = () => {
    userStore.clearSession();
    logout({
      logoutParams: { returnTo: window.location.origin },
    });
  };

  const minutesLeft = Math.ceil(tokenRemainingTime / (60 * 1000));

  return (
    <Modal
      backdrop={"blur"}
      isOpen={isOpen}
      style={{
        zIndex: 100001,
      }}
      onOpenChange={(isOpen) => {
        if (!isOpen) {
          onClose();
        }
      }}
      classNames={{
        wrapper: "z-[100002]",
        backdrop: "z-[100001]",
      }}
    >
      <ModalContent className="relative w-full max-w-[400px] border-none border-foreground/10 bg-[url('https://nextuipro.nyc3.cdn.digitaloceanspaces.com/components-images/abstract-dark-bg4.jpg')] bg-right-bottom">
        <ModalHeader className="flex flex-col pb-0">
          <h1 className="mb-2 text-default-400">
            <Input
              label={t("token_expires_in")}
              value={formattedTokenRemainingTime}
              isReadOnly
              disabled
              classNames={{
                inputWrapper: [
                  "bg-transparent",
                  "group-data-[focus=true]:bg-transparent",
                  "hover:bg-transparent",
                  "data-[hover=true]:bg-transparent",
                  "px-0",
                ],
                innerWrapper: [
                  "bg-transparent",
                  "group-data-[focus=true]:bg-transparent",
                  "hover:bg-transparent",
                ],
              }}
              aria-label={t("token_expires_in")}
            />
          </h1>
        </ModalHeader>
        <ModalBody className="relative flex gap-0 p-8">
          <h2 className="inline bg-gradient-to-br from-foreground-800 to-foreground-500 bg-clip-text text-6xl font-semibold tracking-tight text-transparent dark:to-foreground-200">
            {minutesLeft}
            <br />
            {t("minutes")}
            <br />
            {t("left")}
          </h2>
        </ModalBody>

        <ModalFooter className="">
          <div className="flex flex-col gap-4 w-full items-center">
            <div className="flex flex-col px-6 w-full">
              <Progress
                size="md"
                radius="md"
                value={tokenProgress}
                color={tokenColor}
                showValueLabel={true}
                className="p-2"
                aria-label={t("token_progress")}
              />
            </div>
            <div className="w-full mt-2">
              <Button
                fullWidth
                className="border-small border-white/20 bg-white/10 text-white"
                onPress={handleLogout}
              >
                {t("reconnect_now")}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
});

export default AlertModalTokenExpire;
