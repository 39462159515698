export interface Background {
  id: number;
  name: string;
  url: string;
}

export const BACKGROUNDS: Background[] = [
  { id: 1, name: "Background 1", url: "/images/bg1.png" },
  { id: 2, name: "Background 2", url: "/images/bg2.png" },
  { id: 3, name: "Background 3", url: "/images/bg3.png" },
  { id: 4, name: "Background 4", url: "/images/bg4.png" },
  // { id: 5, name: "Background 5", url: "/videos/waves.mp4" },
];

export const DEFAULT_BACKGROUND = BACKGROUNDS[0].url;

export const isImage = (url: string) => /\.(png|jpe?g|gif)$/i.test(url);
export const isVideo = (url: string) => /\.(mp4|mov|webm)$/i.test(url);
