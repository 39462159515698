import { makeAutoObservable } from "mobx";

class FormStore {
  name: string = "";
  description: string = "";
  llmChoice: string = "gpt-3.5-turbo";

  personalityPrompt: string = "";

  knowledgeLink: string = "";

  deployURL: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setName(name: string) {
    this.name = name;
  }

  setDescription(description: string) {
    this.description = description;
  }

  setLLMChoice(llmChoice: string) {
    this.llmChoice = llmChoice;
  }

  setPersonalityPrompt(prompt: string) {
    this.personalityPrompt = prompt;
  }

  setKnowledgeLink(link: string) {
    this.knowledgeLink = link;
  }

  setDeployURL(url: string) {
    this.deployURL = url;
  }

  reset() {
    this.name = "";
    this.description = "";
    this.llmChoice = "gpt-3.5-turbo";
    this.personalityPrompt = "";
    this.knowledgeLink = "";
    this.deployURL = "";
  }
}

const formStore = new FormStore();
export default formStore;
